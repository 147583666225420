import Container from "../../components/control/container";
import strings from "../../lang/strings";
import { PageSettings } from "../../config/page-settings";
import { FORMAT } from "../../config/const";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { useContext, useRef } from "react";
import dayjs from "dayjs";

const Affiliate = ({ history }) => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => tableRef.current?.draw(refresh);

  const searchOptions = [
    { value: 'name', name: strings.common.name },
    { value: 'phone', name: strings.common.phone },
  ];

  const onDetail = id => history.push(`/affiliate/${id}`);

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        `facility/affiliate/${id}`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  const getFilterOption = () => {
    return {
      column: $('#searchColumn').val(),
      keyword: $('#searchInput').val(),
    }
  }

  const onDownload = () => {
    context.get(
      'facility/affiliate/list',
      {
        start: 0,
        length: 0,
        ...getFilterOption(),
        download: true
      },
      response => {
        const excelData = [];
        response.forEach(it => {
          excelData.push([
            it.id,
            it.name,
            context.getSettingList('category').find(c => c.id == it.category)?.name || '-',
            context.getSettingList('area').find(a => a.id == it.area)?.name || '-',
            it.status == 0 ? strings.affiliate_request.unread : strings.affiliate_request.read,
            it.create_date,
          ]);
        });

        context.downloadExcel(`affiliate-visit-${dayjs().format(FORMAT.DATE)}.csv`, [
          [
            '#',
            strings.facility.member_name,
            strings.facility.category,
            strings.facility.address,
            strings.common.status,
            strings.affiliate_request.received_date,
            strings.common.manage,
          ],
          ...excelData
        ]);
      }
    );
  };

  return (
    <Container title={strings.menu.affiliate}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => refreshTable(true)}>
              {strings.common.search}
            </button>
            <button type='button' className='btn btn-primary btn-sm ml-md-4'
                    onClick={() => history.push(`/facility/0`)}>
              {strings.facility.add}
            </button>
            <button type='button' className='btn btn-info btn-sm ml-md-1' onClick={onDownload}>
              {strings.common.download}
            </button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'name', orderable: true,
              }, {
                targets: [columnNo++], data: 'category', orderable: true,
                render: value => context.getSettingList('category').find(it => it.id == value)?.name || '-'
              }, {
                targets: [columnNo++], data: 'area', orderable: false,
                render: value => context.getSettingList('area').find(it => it.id == value)?.name || '-'
              }, {
                targets: [columnNo++], data: 'status', orderable: true,
                render: value => value == 0 ? strings.affiliate_request.unread : strings.affiliate_request.read
              }, {
                targets: [columnNo++], data: 'create_date', orderable: true,
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<button type='button' class='btn btn-outline-success btn-sm m-2'>${strings.modal.confirm}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onDetail(item.id))
                    .end()
                    .append(`<button type='button' class='btn btn-outline-danger btn-sm m-2'>${strings.common.delete}</button>`)
                    .find('button:eq(1)')
                    .on('click', () => onDelete(item.id));
                }
              }],
              order: [[0, 'DESC']],
              ajax: {
                url: 'facility/affiliate/list',
                data: (req) => {
                  Object.assign(req, getFilterOption());
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.facility.member_name}</th>
              <th>{strings.facility.category}</th>
              <th>{strings.facility.address}</th>
              <th>{strings.common.status}</th>
              <th>{strings.affiliate_request.received_date}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
    </Container>
  );

}

export default withRouter(Affiliate);
