import strings from "../../lang/strings";
import { useEffect, useState } from "react";
import querystring from "query-string";
import { Panel, PanelBody } from "../../components/panel/panel";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import FacilityVisitorFragment from "./fragment/visitor-fragment";
import FacilityInfoFragment from "./fragment/info-fragment";

const FacilityDetail = ({ id = 0, affiliate = 0, history, location }) => {

  const tabs = [
    {
      title: strings.facility.main_info,
      component: (id, affiliate) => <FacilityInfoFragment id={id} affiliate={affiliate} />
    },
    { title: strings.facility.visitor, component: (id) => <FacilityVisitorFragment id={id} /> },
  ];
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const searchQS = querystring.parse(location.search);
    setActiveTab(searchQS.tab || 0);
  }, [location.search]);

  const toggleTab = tab => {
    const searchQS = {};
    searchQS.tab = tab;
    history.push('?' + querystring.stringify(searchQS));
  }

  return (
    <Panel>
      <Nav tabs className="nav-tabs-inverse">
        {tabs.map((menu, idx) => (
          <NavItem key={menu.title}>
            <NavLink className={classnames({ active: activeTab == idx })} onClick={() => toggleTab(idx)}>
              <span className="d-none d-xs-inline">{menu.title}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <PanelBody>
        {tabs[activeTab].component(id, affiliate)}
      </PanelBody>
    </Panel>
  );

}

export default withRouter(FacilityDetail);
