import React, { useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import { STATUS } from "../../../config/const";

const ReservationPopup = ({ isOpen, id, onClose }) => {

  const context = useContext(PageSettings);

  const [reservation, setReservation] = useState(null);
  const [user, setUser] = useState(null);
  const [reviewList, setReviewList] = useState([]);

  const getInfo = () => {
    if (id < 1) {
      return;
    }

    context.get(
      `facility/reservation/detail/${id}`,
      {},
      res => {
        setReservation(res.reservation);
        setUser(res.user);
        setReviewList(res.review_list);
      }
    );
  }

  const onConfirm = (status) => {
    context.showConfirm(strings.reservation.confirm_msg, async res => {
      if (res !== true) {
        return;
      }

      context.post(
        `facility/reservation/confirm/${id}`,
        { status },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          onClose(true);
        }
      );
    });
  }

  return (
    <Modal size={'lg'} isOpen={isOpen} toggle={onClose} onOpened={getInfo}>
      <ModalHeader className="popup-nickname" toggle={onClose}>
        {strings.reservation.detail}
      </ModalHeader>
      <ModalBody>
        <div className='row'>
          <div className='col-xs-2'>
            <img className='img-thumbnail img-circle' src={user?.profile.getFullUrl()} />
          </div>
          <table className="col-xs-3">
            <tbody>
            <tr>
              <th>{strings.common.name}</th>
              <td>{user?.name}</td>
            </tr>
            <tr>
              <th>{strings.facility.id}</th>
              <td>{user?.email}</td>
            </tr>
            <tr>
              <th>{strings.facility.contact}</th>
              <td>{user?.phone}</td>
            </tr>
            <tr>
              <th>{strings.facility.member_level}</th>
              <td>{context.getSettingList('level').find(it => it.id == user?.level)?.name || '-'}</td>
            </tr>
            </tbody>
          </table>
          <table className="col-xs-3">
            <tbody>
            <tr>
              <th>{strings.common.age}</th>
              <td>{strings.formatString(strings.push_history.age, user?.age ?? 0)}</td>
            </tr>
            <tr>
              <th>{strings.common.gender}</th>
              <td>{strings.push_history.gender[user?.gender]}</td>
            </tr>
            <tr>
              <th>{strings.member.interest_category}</th>
              <td>{context.getSettingList('category').filter(it => user?.interest_category?.split(',')?.includes(`${it.id}`))?.map(it => it.name)?.join(', ') || '-'}</td>
            </tr>
            <tr>
              <th>{strings.reservation.visit_count}</th>
              <td>{user?.visit_count}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className='row m-t-10'>
          <table className='table table-bordered'>
            <thead>
            <tr>
              <th style={{ width: '70%' }}>{strings.common.content}</th>
              <th>{strings.common.create_date}</th>
            </tr>
            </thead>
            <tbody>
            {
              reviewList.length > 0 ?
                reviewList.map((tr, idx) => (
                  <tr key={idx}>
                    <td>{tr.content}</td>
                    <td>{tr.review_date}</td>
                  </tr>
                ))
                :
                <tr>
                  <td colSpan={2}>{strings.reservation.no_content}</td>
                </tr>
            }
            </tbody>
          </table>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" type='button' onClick={onClose}>{strings.modal.close}</button>
        {
          reservation?.status == STATUS.PENDING &&
          <>
            <button className="btn btn-danger" type='button' onClick={() => onConfirm(STATUS.DELETED)}>
              {strings.reservation.deny}
            </button>
            <button className="btn btn-success" type='button' onClick={() => onConfirm(STATUS.NORMAL)}>
              {strings.reservation.confirm}
            </button>
          </>
        }
      </ModalFooter>
    </Modal>
  );
};

export default ReservationPopup;
