import React from 'react';
import { Route } from 'react-router-dom';
import SidebarNavList from './sidebar-nav-list.jsx';
import menus from '../../config/page-route';
import { inject } from "mobx-react";

class SidebarNav extends React.Component {

  constructor(props) {
    super(props);
    let menuList = menus;
    if (this.props.rootStore.getMe?.facility > 0) {
      menuList = menuList.filter(it => !['/facility', '/affiliate', '/member', '/payment', '/category', '/post', '/review_admin', '/change_device'].includes(it.path));
    } else {
      menuList = menuList.filter(it => !['/my_facility', '/trainer', '/facility_member', '/reservation', '/review'].includes(it.path));
    }

    this.state = {
      active: -1,
      clicked: -1,
      menus: menuList
    };
  }

  handleExpand(e, i, match) {
    e.preventDefault();

    if (this.state.clicked === -1 && match) {
      this.setState({
        active: -1,
        clicked: 1
      });
    } else {
      this.setState(state => ({
        active: (state.active === i ? -1 : i),
        clicked: 1
      }));
    }
  }

  render() {
    return (
      <ul className="nav p-t-10">
        {/*<li className="nav-header">&nbsp;</li>*/}
        {this.state.menus.map((menu, i) => (
          <Route path={menu.path} exact={menu.exact} key={i} children={({match}) => (
            <SidebarNavList
              data={menu}
              key={i}
              expand={(e) => this.handleExpand(e, i, match)}
              active={i === this.state.active}
              clicked={this.state.clicked}
            />
          )}/>
        ))}
      </ul>
    );
  }
}

export default inject('rootStore')(SidebarNav);
