import React from 'react';
import { Panel, PanelBody, PanelHeader } from "../panel/panel";

const Container = ({ title, rightButton, children }) => {
  return (
    <Panel>
      <PanelHeader noButton={true} rightButton={rightButton}>{title}</PanelHeader>
      <PanelBody>
        {children}
      </PanelBody>
    </Panel>
  );
}

export default Container;
