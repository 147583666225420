import strings from "../../../lang/strings";
import { PageSettings } from "../../../config/page-settings";
import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import $ from "jquery";
import { useContext, useEffect, useRef, useState } from "react";
import DateTime from "react-datetime";
import { FORMAT } from "../../../config/const";
import { getDateRange } from "../../../helper/extension";
import dayjs from "dayjs";

const FacilityVisitorFragment = ({ id }) => {

  const context = useContext(PageSettings);

  const ageList = context.getSettingList('age');
  const levelList = context.getSettingList('level');
  const categoryList = context.getSettingList('category');

  const [gender, setGender] = useState(Object.keys(strings.push_history.gender));
  const [age, setAge] = useState(ageList);
  const [rating, setRating] = useState(levelList.map(it => it.id));
  const [category, setCategory] = useState(categoryList.map(it => it.id));
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const genderRef = useRef('');
  const ageRef = useRef('');
  const ratingRef = useRef('');
  const categoryRef = useRef('');
  const fromRef = useRef('');
  const toRef = useRef('');

  useEffect(() => {
    genderRef.current = gender.join(',');
  }, [gender]);
  useEffect(() => {
    ageRef.current = age.join(',');
  }, [age]);
  useEffect(() => {
    ratingRef.current = rating.join(',');
  }, [rating]);
  useEffect(() => {
    categoryRef.current = category.join(',');
  }, [category]);
  useEffect(() => {
    fromRef.current = from;
  }, [from]);
  useEffect(() => {
    toRef.current = to;
  }, [to]);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => tableRef.current?.draw(refresh);

  const searchOptions = [
    { value: 'name', name: strings.common.name },
    { value: 'phone', name: strings.common.phone },
  ];

  const onDate = (idx) => {
    const { from, to } = getDateRange(idx);
    setFrom(from);
    setTo(to);
  };

  const getFilterOption = () => {
    return {
      type: 'table',
      id: id,
      gender: genderRef.current,
      age: ageRef.current,
      level: ratingRef.current,
      category: categoryRef.current,
      from: fromRef.current,
      to: toRef.current,
      column: $('#searchColumn').val(),
      keyword: $('#searchInput').val(),
    };
  }

  const onDownload = () => {
    context.get(
      'facility/member/list',
      {
        start: 0,
        length: 0,
        ...getFilterOption(),
        download: true
      },
      response => {
        const excelData = [];
        response.forEach(it => {
          excelData.push([
            it.id,
            it.email,
            it.name,
            it.phone,
            strings.push_history.gender[it.gender],
            strings.formatString(strings.push_history.age, it.age),
            levelList.find(l => l.id == it.level)?.name || '-',
            it.visit_date
          ]);
        });

        context.downloadExcel(`facility-visit-member-${dayjs().format(FORMAT.DATE)}.csv`, [
          [
            '#',
            strings.facility.id,
            strings.facility.member_name,
            strings.facility.contact,
            strings.common.gender,
            strings.common.age,
            strings.facility.member_level,
            strings.facility.visit_date,
          ],
          ...excelData
        ]);
      }
    );
  };

  const onMultiCheck = (field, value) => {
    switch (field) {
      case 'age':
        setAge(value ? ageList : []);
        break;
      case 'level':
        setRating(value ? levelList.map(it => it.id) : []);
        break;
      case 'category':
        setCategory(value ? categoryList.map(it => it.id) : []);
        break;
      default:
    }
  };

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-group row form-inline'>
          <label className='col-md-1 col-form-label'>{strings.common.gender}</label>
          <div className='col-md-10'>
            <div className='form-inline'>
              {
                Object.keys(strings.push_history.gender).map((sex, idx) => (
                  <label key={idx} className='control-label m-r-20'>
                    <input
                      type='checkbox' name='gender' checked={gender.includes(sex)} value={sex}
                      onChange={() => {
                        setGender(prev => prev.includes(sex) ? prev.filter(it => it != sex) : [...prev, sex]);
                      }} />&nbsp;{strings.push_history.gender[sex]}
                  </label>
                ))
              }
            </div>
          </div>
        </div>
        <div className='form-group row form-inline'>
          <label className='col-md-1 col-form-label'>{strings.common.age}</label>
          <div className="col-md-10">
            <label className='control-label form-inline justify-content-start m-b-5'>
              <input type='checkbox' name='age'
                     checked={age.length == ageList.length}
                     onChange={(e) => onMultiCheck('age', e.target.checked)}
              />&nbsp;{strings.common.select_all}
            </label>
            <div className='form-inline'>
              {
                ageList.map((it, idx) => (
                  <label key={idx} className='control-label m-r-20'>
                    <input
                      type='checkbox' name='age' checked={age.includes(it)} value={it}
                      onChange={() => {
                        setAge(prev => prev.includes(it) ? prev.filter(p => p != it) : [...prev, it]);
                      }} />&nbsp;{strings.formatString(strings.push_history.age, it)}
                  </label>
                ))
              }
            </div>
          </div>
        </div>
        <div className='form-group row form-inline'>
          <label className='col-md-1 col-form-label'>{strings.facility.rating}</label>
          <div className="col-md-10">
            <label className='control-label m-b-5 form-inline justify-content-start'>
              <input type='checkbox' name='level'
                     checked={rating.length == levelList.length}
                     onChange={(e) => onMultiCheck('level', e.target.checked)}
              />&nbsp;{strings.common.select_all}
            </label>
            <div className='form-inline'>
              {
                levelList.map((level, idx) => (
                  <label key={idx} className='control-label m-r-20'>
                    <input
                      type='checkbox' name='level' checked={rating.includes(level.id)} value={level.id}
                      onChange={() => {
                        setRating(prev => prev.includes(level.id) ? prev.filter(it => it != level.id) : [...prev, level.id]);
                      }} />&nbsp;{level.name}
                  </label>
                ))
              }
            </div>
          </div>
        </div>
        <div className='form-group row form-inline'>
          <label className='col-md-1 col-form-label'>{strings.member.interest_category}</label>
          <div className="col-md-10">
            <label className='control-label m-b-5 form-inline justify-content-start'>
              <input type='checkbox' name='category'
                     checked={category.length == categoryList.length}
                     onChange={(e) => onMultiCheck('category', e.target.checked)}
              />&nbsp;{strings.common.select_all}
            </label>
            <div className='form-inline'>
              {
                categoryList.map((cate, idx) => (
                  <label key={idx} className='control-label m-r-20'>
                    <input
                      type='checkbox' name='category' checked={category.includes(cate.id)} value={cate.id}
                      onChange={() => {
                        setCategory(prev => prev.includes(cate.id) ? prev.filter(it => it != cate.id) : [...prev, cate.id]);
                      }} />&nbsp;{cate.name}
                  </label>
                ))
              }
            </div>
          </div>
        </div>
        <div className='form-group row form-inline'>
          <label className='col-md-1 col-form-label'>{strings.facility.visit_date}</label>
          <div className='col-md-11'>
            <div className='form-inline'>
              <DateTime
                dateFormat={FORMAT.DATE}
                timeFormat={false}
                inputProps={{ placeholder: strings.push_history.date_from }}
                value={from}
                closeOnSelect={true}
                className="ml-md-2"
                onChange={(e) => {
                  if (typeof e === 'string') {
                    setFrom(e);
                  } else {
                    setFrom(e.format(FORMAT.DATE));
                  }
                }} />
              <label className='control-label'>&nbsp;~</label>
              <DateTime
                dateFormat={FORMAT.DATE}
                timeFormat={false}
                inputProps={{ placeholder: strings.push_history.date_to }}
                value={to}
                closeOnSelect={true}
                className="ml-md-1 mr-4"
                onChange={(e) => {
                  if (typeof e === 'string') {
                    setTo(e);
                  } else {
                    setTo(e.format(FORMAT.DATE));
                  }
                }} />
              {
                strings.facility.date_list.map((it, idx) => (
                  <button key={idx} className='btn btn-sm btn-default mr-1' onClick={() => onDate(idx)}>{it}</button>
                ))
              }
            </div>
          </div>
        </div>
        <div className='form-horizontal row form-inline'>
          <label className='col-md-1 col-form-label'>{strings.common.keyword}</label>
          <div className='col-md-10'>
            <div className='form-inline'>
              <SearchSelect
                id='searchColumn'
                value={searchOptions[0].value}
                data={searchOptions}
                onChange={() => refreshTable(true)} />
              <SearchInput
                id='searchInput'
                action={() => refreshTable(true)} />
              <button type='button' className='btn btn-success btn-sm ml-md-5' onClick={() => refreshTable(true)}>
                {strings.common.search}
              </button>
              <div className='ml-md-1'>
                <button type='button' className='btn btn-info btn-sm' onClick={onDownload}>
                  {strings.common.download}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className='mt-3' />
      <div className='col-md-12'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'email', orderable: true,
            }, {
              targets: [columnNo++], data: 'name', orderable: true,
            }, {
              targets: [columnNo++], data: 'phone', orderable: true,
            }, {
              targets: [columnNo++], data: 'gender', orderable: false,
              render: value => strings.push_history.gender[value]
            }, {
              targets: [columnNo++], data: 'age', orderable: true,
              render: value => strings.formatString(strings.push_history.age, value)
            }, {
              targets: [columnNo++], data: 'level', orderable: true,
              render: value => levelList.find(it => it.id == value)?.name || '-'
            }, {
              targets: [columnNo++], data: 'visit_date', orderable: false,
            }],
            fnCreatedRow: function (row, item, index) {
                $('td:eq(0)', row).html((index + 1));
            },
            order: [[0, 'DESC']],
            ajax: {
              url: 'facility/member/list',
              data: (req) => {
                Object.assign(req, getFilterOption());
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.facility.id}</th>
            <th>{strings.facility.member_name}</th>
            <th>{strings.facility.contact}</th>
            <th>{strings.common.gender}</th>
            <th>{strings.common.age}</th>
            <th>{strings.facility.member_level}</th>
            <th>{strings.facility.visit_date}</th>
          </tr>
          </thead>
          <tbody />
        </DataTable>
      </div>
    </div>
  );

}

export default FacilityVisitorFragment;
