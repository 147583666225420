import ko from "./locales/ko";
import en from "./locales/en";
import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
  en, ko
});
strings.setLanguage('ko');

export default strings;
