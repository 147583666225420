import { PageSettings } from "../../../config/page-settings";
import { Fragment, useContext, useEffect, useState } from "react";
import strings from "../../../lang/strings";
import ImageSelect from "../../../components/control/image-select";
import { withRouter } from "react-router-dom";
import { STATUS } from "../../../config/const";

const MemberInfoFragment = ({ id, history }) => {

  const context = useContext(PageSettings);

  const [info, setInfo] = useState(null);
  const [profile, setProfile] = useState({
    url: '',
    file: null
  }); // 프로필이미지

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    if (id > 0) {
      context.get(
        `member/detail/${id}`,
        {},
        response => {
          setInfo(response);
          setProfile({
            url: response.profile,
            file: null
          });
        }
      );
    }
  }

  const onImageChange = e => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setProfile({ url: reader.result, file });
    };
    reader.readAsDataURL(file);
  };

  const onImageDelete = () => {
    if (profile.file != null) {
      setProfile({
        url: info?.profile ?? '',
        file: null
      });
      return;
    }

    setInfo({
      ...info,
      profile: ''
    });
    setProfile({
      url: '',
      file: null
    });
  };

  const uploadImage = () => {
    if (profile.file == null) {
      return profile.url;
    }

    return new Promise(resolve => {
      let formData = new FormData();
      formData.append('file', profile.file, profile.file.name);

      context.post(
        'upload/image/member',
        formData,
        response => {
          resolve(response.image);
        }
      );
    });
  }

  const onAreaSelect = (checked, parent_id) => {
    const selectedArea = context.getSettingList('area').find(it => it.id == parent_id);
    setInfo(prev => {
      return {
        ...prev,
        area: checked ?
          (prev?.area?.filter(it => !selectedArea.children.some(t => t.id == it)) ?? []).concat(selectedArea.children.map(it => it.id)) :
          prev?.area?.filter(it => !selectedArea.children.some(t => t.id == it))
      }
    });
  }

  const onResetPwd = () => {
    context.showConfirm(strings.member.reset_pwd_confirm, async res => {
      if (res !== true) {
        return;
      }

      context.post(
        `member/reset_pwd/${id}`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
        }
      );
    });
  }

  const onSignOut = () => {
    context.showConfirm(strings.member.sign_out_confirm, async res => {
      if (res !== true) {
        return;
      }

      context.post(
        `member/leave/${id}`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          getUserInfo();
        }
      );
    });
  }

  const onSave = e => {
    e.preventDefault();

    /*if ((info?.type ?? '') == '') {
      context.showAlert(strings.push_history.select_type);
      return;
    }*/

    if (info?.category?.length < 1) {
      context.showAlert(strings.facility.select_category);
      return;
    }
    if (info?.area?.length < 1) {
      context.showAlert(strings.facility.select_area);
      return;
    }

    context.showConfirm(strings.modal.save_confirm, async res => {
      if (res !== true) {
        return;
      }

      const image = await uploadImage();
      context.post(
        `member/save/${id}`,
        {
          name: info?.name || '',
          phone: info?.phone || '',
          email: info?.email || '',
          age: info?.age || '',
          gender: info?.gender || '',
          level: info?.level || '',
          category: info?.category?.join(',') || '',
          area: info?.area?.join(',') || '',
          profile: image,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          getUserInfo();
        }
      );
    });
  }

  return (
    <form className='form-horizontal form-bordered' onSubmit={onSave}>
      <div className='form-group row'>
        <div className='col-xs-2'>
          <ImageSelect
            width='80%'
            height='80%'
            image_url={profile.url?.getFullUrl()}
            onSelect={onImageChange}
            onDelete={onImageDelete} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'이름'}</label>
        <div className="col-xs-6">
          <input type='text' autoComplete='off' className='form-control'
            value={info?.name || ''} required
            onChange={e => setInfo({ ...info, name: e.target.value })} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.facility.contact}</label>
        <div className="col-xs-6">
          <input type='text' autoComplete='off' className='form-control'
            value={info?.phone || ''} required
            onChange={e => setInfo({ ...info, phone: e.target.value })} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.member.email}</label>
        <div className="col-xs-6">
          <input type='email' autoComplete='off' className='form-control'
            value={info?.email || ''} required
            onChange={e => setInfo({ ...info, email: e.target.value })} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.common.age}</label>
        <div className="col-xs-6">
          <select className='form-control' value={info?.age} required
            onChange={e => setInfo({ ...info, age: e.target.value })}>
            {
              context.getSettingList('age').map((age, idx) => (
                <option key={idx} value={age}>{strings.formatString(strings.push_history.age, age)}</option>
              ))
            }
          </select>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.common.gender}</label>
        <div className="col-xs-6">
          <select className='form-control' required value={info?.gender}
            onChange={e => setInfo({ ...info, gender: e.target.value })}>
            {
              Object.keys(strings.push_history.gender).map((gender, idx) => (
                <option key={idx} value={gender}>{strings.push_history.gender[gender]}</option>
              ))
            }
          </select>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'회원유형'}</label>
        <div className="col-xs-6">{(info?.company_name != null && info?.company_name != '') ? '기업회원' : '일반회원'}</div>
      </div>
      {
        info?.company_name != null && info?.company_name != '' &&
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{'기업명'}</label>
          <div className="col-xs-6">
            <input type='text' autoComplete='off' className='form-control'
              value={info?.company_name || ''} required
              onChange={e => setInfo({ ...info, company_name: e.target.value })} />
          </div>
        </div>
      }
      {
        info?.company_name != null && info?.company_name != '' &&
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{'재직증명서'}</label>
          <div className="col-xs-3">
            <img
              width='100%'
              height='100%'
              src={info?.company_photo?.getFullUrl()}
              alt="사원증, 재직증명서 사진" />
          </div>
        </div>
      }
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.member.interest_category}</label>
        <div className="col-xs-6">
          <div className='form-inline'>
            {
              context.getSettingList('category').map((category, idx) => (
                <label key={idx} className='control-label m-r-20'>
                  <input
                    type='checkbox' name='category' checked={info?.category?.includes(category.id)}
                    onChange={() => {
                      setInfo(prev => {
                        return {
                          ...prev,
                          category: prev?.category?.includes(category.id) ? prev?.category?.filter(it => it != category.id) : [...(prev?.category ?? []), category.id]
                        };
                      });
                    }}
                    value={category.id} />&nbsp;{category.name}
                </label>
              ))
            }
          </div>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.member.interest_area}</label>
        <div className="col-xs-6">
          <table className='table table-bordered mb-0'>
            <tbody>
              {
                context.getSettingList('area').map((parent, idx) => (
                  <Fragment key={idx}>
                    <tr>
                      <td rowSpan={2}>{parent.name}</td>
                      <td>
                        <label className='control-label form-inline mb-0'>
                          <input type='checkbox' name='category'
                            checked={parent.children.every(it => info?.area?.includes(it.id))}
                            onChange={e => onAreaSelect(e.target.checked, parent.id)}
                          />&nbsp;{strings.common.select_all}
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-left'>
                        {
                          parent.children.map((child, childIdx) => (
                            <label key={childIdx} className='control-label mb-0 m-r-10'>
                              <input
                                type='checkbox' name='category' checked={info?.area?.includes(child.id)}
                                onChange={() => {
                                  setInfo(prev => {
                                    return {
                                      ...prev,
                                      area: prev?.area?.includes(child.id) ?
                                        prev?.area?.filter(it => it != child.id) :
                                        prev?.area?.concat(child.id)
                                    }
                                  });
                                }} />&nbsp;{child.name}
                            </label>
                          ))
                        }
                      </td>
                    </tr>
                  </Fragment>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.member.membership}</label>
        <div className="col-xs-6">
          <select className='form-control' required value={info?.level}
            onChange={e => setInfo({ ...info, level: e.target.value })}>
            <option value='' />
            {
              context.getSettingList('level').map((level, idx) => (
                <option key={idx} value={level.id}>{level.name}</option>
              ))
            }
          </select>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.member.this_month_remain}</label>
        <div className="col-xs-6">{info?.ticket_info?.this_month || '-'}</div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.member.last_month_remain}</label>
        <div className="col-xs-6">{info?.ticket_info?.last_month || '-'}</div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.member.remain_gift}</label>
        <div className="col-xs-6">{info?.ticket_info?.gift_cnt || '-'}</div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.member.avg_remain}</label>
        <div className="col-xs-6">{info?.ticket_info?.avg_remain || '-'}</div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.member.join_date}</label>
        <div className="col-xs-6">{info?.signup_date || '-'}</div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.member.membership_date}</label>
        <div className="col-xs-6">{info?.membership_date || '-'}</div>
      </div>
      <div className='form-group row m-t-20'>
        <button type='submit' className='btn btn-primary col-xs-2 m-l-15'>
          {strings.common.save}
        </button>
        <button type='button' className='btn btn-info col-xs-2 m-l-15' onClick={onResetPwd}>
          {strings.member.reset_pwd}
        </button>
        {
          info?.status != STATUS.DELETED &&
          <button type='button' className='btn btn-danger col-xs-2 m-l-15' onClick={onSignOut}>
            {strings.member.sign_out}
          </button>
        }
      </div>
    </form>
  );

}

export default withRouter(MemberInfoFragment);
