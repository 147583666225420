import Container from "../../components/control/container";
import strings from "../../lang/strings";
import { PageSettings } from "../../config/page-settings";
import { FORMAT, STATUS } from "../../config/const";
import DataTable from "../../components/control/data-table";
import { useContext, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { inject } from "mobx-react";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import $ from "jquery";
// import ReservationPopup from "./popup/reservation-popup";

const ReviewAdmin = ({ rootStore }) => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => tableRef.current?.draw(refresh);

  const searchOptions = [
    { value: 'email', name: strings.facility.id },
    { value: 'name', name: strings.common.name },
    { value: 'phone', name: strings.common.phone },
  ];

  const [showPopup, togglePopup] = useState(false);
  const [id, setId] = useState(0);

  useEffect(() => {
    refreshTable(true);
  }, []);

  const showDetail = id => {
    setId(id);
    togglePopup(true);
  }

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        `facility/review/delete/${id}`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <Container title={strings.menu.review}>
      <div className='row'>
        <div className='form-group row form-inline m-t-10'>
          <div className='col-md-10'>
            <div className='form-inline'>
              <SearchSelect
                id='searchColumn'
                value={searchOptions[0].value}
                data={searchOptions}
                onChange={() => refreshTable(true)} />
              <SearchInput
                id='searchInput'
                action={() => refreshTable(true)} />
              <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => refreshTable(true)}>
                {strings.common.search}
              </button>
            </div>
          </div>
        </div>
        <div className='col-md-12'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'facility_name', orderable: false,
              }, {
                targets: [columnNo++], data: 'user_profile', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<img src='${('' + value).getFullUrl()}' style="width: 100px;height: 100px;object-fit: cover;" />`);
                }
              }, {
                targets: [columnNo++], data: 'user_email', orderable: false,
              }, {
                targets: [columnNo++], data: 'star', orderable: false,
              }, {
                targets: [columnNo++], data: 'content', orderable: false,
              }, {
                targets: [columnNo++], data: 'review_date', orderable: false,
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<button class="btn btn-outline-info">리뷰 삭제</button>`)
                    .find('button')
                    .bind('click', () => onDelete(item.id));
                }
              }],
              fnCreatedRow: function (row, item, index) {
                $('td:eq(0)', row).html((index + 1));
              },
              order: [[0, 'DESC']],
              ajax: {
                url: 'facility/review/list',
                data: (req) => {
                  req.facility = rootStore.getMe?.facility;
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
              <tr>
                <th>#</th>
                <th>{'시설'}</th>
                <th>{'프로필'}</th>
                <th>{'ID'}</th>
                <th>{'별점'}</th>
                <th>{'내용'}</th>
                <th>{'등록일자'}</th>
                <th>{'관리'}</th>
              </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
      {/* <ReservationPopup
        isOpen={showPopup}
        id={id}
        onClose={(refresh = false) => {
          togglePopup(false);
          if (refresh) {
            refreshTable(false);
          }
        }} /> */}
    </Container>
  );

}

export default inject('rootStore')(ReviewAdmin);
