import React, { useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";

const AddTicketPopup = ({ isOpen, id, onClose }) => {

  const context = useContext(PageSettings);

  const [ticket, setTicket] = useState(0);

  const onConfirm = e => {
    e.preventDefault();

    context.post(
      `member/add_ticket/${id}`,
      {
        ticket: ticket,
      },
      () => {
        onClose(true);
      }
    );
  }

  return (
    <Modal size={'md'} isOpen={isOpen} toggle={onClose}>
      <ModalHeader className="popup-nickname" toggle={onClose}>
        {'티켓 추가'}
      </ModalHeader>
      <ModalBody>
        <div className='row'>
          <label className='col-xs-2 col-form-label'>{'추가할 티켓 수'}</label>
          <div className="col-xs-9">
            <input
              required
              type='number'
              className='form-control'
              value={ticket}
              onChange={e => setTicket(e.target.value)} />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" type='button' onClick={onClose}>{strings.modal.close}</button>
        <button className="btn btn-success" type='button' onClick={onConfirm}>{strings.modal.confirm}</button>
      </ModalFooter>
    </Modal>
  );
};

export default AddTicketPopup;
