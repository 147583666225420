import strings from "../../../lang/strings";
import { useContext, useRef, useState } from "react";
import { PageSettings } from "../../../config/page-settings";
import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import $ from "jquery";
import CategoryPopup from "../popup/category-popup";

const CategoryFragment = () => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => tableRef.current?.draw(refresh);

  const searchOptions = [
    { value: 'name', name: strings.common.name },
  ];

  const [popup, setPopup] = useState({
    isOpen: false,
    id: 0,
  });
  const showPopup = (id) => setPopup({
    isOpen: true,
    id,
  });
  const hidePopup = () => setPopup({
    isOpen: false,
    info: 0,
  });

  const onEdit = (id) => showPopup(id);

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        `support/category/${id}`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          context.getSettingInfo();
          refreshTable(false);
        }
      );
    });
  };

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-horizontal form-inline'>
          <SearchSelect
            id='searchColumn'
            value={searchOptions[0].value}
            data={searchOptions}
            onChange={() => refreshTable(true)} />
          <SearchInput
            id='searchInput'
            action={() => refreshTable(true)} />
          <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => refreshTable(true)}>
            {strings.common.search}
          </button>
          <button type='button' className='btn btn-primary btn-sm ml-md-2' onClick={() => onEdit(0)}>
            {strings.common.add}
          </button>
        </div>
      </div>
      <div className='col-md-12 mt-3'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'name', orderable: true,
            }, {
              targets: [columnNo++], data: 'count', orderable: true,
              render: value => (value == 0 ? '제한 없음' : value + '회')
            }, {
              targets: [columnNo++], data: 'id', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html(`<button type='button' class='btn btn-outline-success btn-sm m-2'>${strings.common.edit}</button>`)
                  .find('button:eq(0)')
                  .on('click', () => onEdit(item.id))
                  .end()
                  .append(`<button type='button' class='btn btn-outline-danger btn-sm m-2'>${strings.common.delete}</button>`)
                  .find('button:eq(1)')
                  .on('click', () => onDelete(item.id));
              }
            }],
            fnCreatedRow: function (row, item, index) {
                $('td:eq(0)', row).html((index + 1));
            },
            order: [[0, 'DESC']],
            ajax: {
              url: 'support/category',
              data: (req) => {
                req.column = $('#searchColumn').val();
                req.keyword = $('#searchInput').val();
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.common.name}</th>
            <th>{'월간 사용가능 횟수'}</th>
            <th>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody />
        </DataTable>
      </div>

      <CategoryPopup
        {...popup}
        onClose={refresh => {
          context.getSettingInfo();
          refreshTable(false);
          if (refresh === true) {
            return;
          }
          hidePopup();
        }} />
    </div>
  );

}

export default CategoryFragment;
