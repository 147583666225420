import React, { useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Redirect, Switch } from 'react-router';
import { inject, observer } from "mobx-react";

import strings from "../../lang/strings";
import Menu, { Extra } from './../../config/page-route.jsx';


const Content = ({ history, rootStore }) => {

  let routes = [...Extra, ...Menu];

  const me = rootStore.getMe;
  if ((me?.facility ?? 0) > 0) {
    // 시설관리자라면 시설관리, 제휴요청 관리, 회원관리, 결제관리, 카테고리 수정, 게시글 관리화면을 노출하지 않는다.
    // routes = routes.filter(it => ['/reservation', '/login'].includes(it.path));
    routes = routes.filter(it => !['/facility', '/affiliate', '/member', '/payment', '/category', '/post', '/review_admin', '/change_device'].includes(it.path));
  } else {
    // 전체관리자라면 내 시설관리, 트레이너 관리, 시설 방문자 관리, 예약관리화면을 노출하지 않는다.
    routes = routes.filter(it => !['/my_facility', '/trainer', '/facility_member', '/reservation', '/review'].includes(it.path));
  }

  const setTitle = path => {
    let pageTitle = strings.admin;

    const route = routes.find(it => it.path === path || it.children?.some(c => c.path === path));
    if (route) {
      if (route.path === path) {
        pageTitle = route.title;
      } else {
        pageTitle = route.children?.find(it => it.path === path)?.title;
      }
    }

    document.title = pageTitle;
  };

  const getRoute = (route, index) =>
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      component={
        !route.private || rootStore.isSignedIn ? route.component : () => <Redirect to='/login'/>
      }
    />;

  useEffect(() => {
    history.listen(() => {
      setTitle(history.location.pathname);
    });

    setTitle(history.location.pathname);
  }, []);

  return (
    <div className={'content'}>
      <Switch>
        {routes.map((route, index) => {
          if (route.hasOwnProperty('children')) {
            return route.children.map((subRoute, subIndex) =>
              getRoute(subRoute, subIndex));
          } else {
            return getRoute(route, index);
          }
        })}
        <Route component={() => <Redirect to='/login'/>}/>
      </Switch>
    </div>
  )
}

export default withRouter(inject('rootStore')(observer(Content)));
