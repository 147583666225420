import React from "react";
import strings from "../lang/strings";

import Login from "../pages/auth/login";
import Dashboard from "../pages/dashboard/dashboard";
import Facility from "../pages/facility";
import Member from "../pages/member";
import Affiliate from "../pages/affiliate";
import Category from "../pages/category";
import Push from "../pages/push";
import Post from "../pages/post";
import FacilityDetail from "../pages/facility/detail";
import MemberDetail from "../pages/member/detail";
import AffiliateDetail from "../pages/affiliate/detail";
import PushDetail from "../pages/push/detail";
import Payment from "../pages/payment";
import PaymentDetail from "../pages/payment/detail";
import Reservation from "../pages/reservation";
import Review from "../pages/review";
import ReviewAdmin from "../pages/review/admin";
import MyFacility from "../pages/facility/my_facility";
import FacilityVisitor from "../pages/facility/visitor";
import Trainer from "../pages/trainer";
import TrainerDetail from "../pages/trainer/detail";
import ChangeDevice from "../pages/device";

export const Extra = [
  {
    path: '/login', title: strings.login.login, exact: true, private: false,
    component: () => <Login />
  },
  {
    path: '/facility/:id', title: strings.facility.detail, exact: true, private: true,
    component: ({ match }) => <FacilityDetail id={match.params.id} />
  },
  {
    path: '/facility/affiliate/:id', title: strings.facility.detail, exact: true, private: true,
    component: ({ match }) => <FacilityDetail affiliate={match.params.id} />
  },
  {
    path: '/member/:id', title: strings.member.detail, exact: true, private: true,
    component: ({ match }) => <MemberDetail id={match.params.id} />
  },
  {
    path: '/affiliate/:id', title: strings.affiliate_request.detail, exact: true, private: true,
    component: ({ match }) => <AffiliateDetail id={match.params.id} />
  },
  {
    path: '/payment/:id', title: strings.payment.detail, exact: true, private: true,
    component: ({ match }) => <PaymentDetail id={match.params.id} />
  },
  {
    path: '/push/:id', title: strings.menu.push, exact: true, private: true,
    component: ({ match }) => <PushDetail id={match.params.id} />
  },
  {
    path: '/trainer/:id', title: strings.trainer.detail, exact: true, private: true,
    component: ({ match }) => <TrainerDetail id={match.params.id} />
  },
];

const Menu = [
  {
    path: '/dashboard', icon: 'fa fa-th', title: strings.menu.dashboard, exact: true, private: true,
    component: () => <Dashboard />
  },
  {
    path: '/facility', icon: 'fa fa-th', title: strings.menu.facility, exact: true, private: true,
    component: () => <Facility />
  },
  {
    path: '/my_facility', icon: 'fa fa-th', title: strings.menu.my_facility, exact: true, private: true,
    component: () => <MyFacility />
  },
  {
    path: '/trainer', icon: 'fa fa-th', title: strings.menu.trainer, exact: true, private: true,
    component: () => <Trainer />
  },
  {
    path: '/member', icon: 'fa fa-th', title: strings.menu.member, exact: true, private: true,
    component: () => <Member />
  },
  {
    path: '/facility_member', icon: 'fa fa-th', title: strings.menu.facility_member, exact: true, private: true,
    component: () => <FacilityVisitor />
  },
  {
    path: '/affiliate', icon: 'fa fa-th', title: strings.menu.affiliate, exact: true, private: true,
    component: () => <Affiliate />
  },
  {
    path: '/reservation', icon: 'fa fa-th', title: strings.menu.reservation, exact: true, private: true, facility: true,
    component: () => <Reservation />
  },
  {
    path: '/payment', icon: 'fa fa-th', title: strings.menu.payment, exact: true, private: true,
    component: () => <Payment />
  },
  {
    path: '/category', icon: 'fa fa-th', title: strings.menu.category, exact: true, private: true,
    component: () => <Category />
  },
  {
    path: '/push', icon: 'fa fa-th', title: strings.menu.push, exact: true, private: true,
    component: () => <Push />
  },
  {
    path: '/post', icon: 'fa fa-th', title: strings.menu.post, exact: true, private: true,
    component: () => <Post />
  },
  {
    path: '/review', icon: 'fa fa-th', title: strings.menu.review, exact: true, private: true,
    component: () => <Review />
  },
  {
    path: '/review_admin', icon: 'fa fa-th', title: strings.menu.review, exact: true, private: true,
    component: () => <ReviewAdmin />
  },
  {
    path: '/change_device', icon: 'fa fa-th', title: strings.menu.change_device, exact: true, private: true,
    component: () => <ChangeDevice />
  },
]

export default Menu;
