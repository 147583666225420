import 'chart.js/auto';
import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import strings from "../../../lang/strings";
import { Link } from "react-router-dom";
import { Line } from 'react-chartjs-2';

const VisitorFragment = () => {

  const context = useContext(PageSettings);

  const options = {
    fill: true,
    responsive: true
  };
  const [data, setData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    context.get(
      'dashboard/visitor',
      {},
      response => {
        let labels = [];
        let data = [];
        for (let date in response) {
          labels.push(date);
          data.push(response[date]);
        }

        setData({
          labels,
          datasets: [{
            label: '이용 회원 수',
            data,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, .5)'
          }]
        });
      }
    );
  }, []);

  return (
    <Container title={'이용 회원 수'} rightButton={(
      <Link to="/facility_member" style={{ color: '#fff' }}>
        {strings.dashboard.see_more} <i className="fa fa-arrow-alt-circle-right" />
      </Link>
    )}>
      <Line type={'line'} options={options} data={data} />
    </Container>
  );
}

export default VisitorFragment;
