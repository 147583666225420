import Container from "../../components/control/container";
import strings from "../../lang/strings";
import { PageSettings } from "../../config/page-settings";
import { FORMAT } from "../../config/const";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { useContext, useRef } from "react";
import dayjs from "dayjs";

const Payment = ({ history }) => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => tableRef.current?.draw(refresh);

  const searchOptions = [
    { value: 'name', name: strings.common.name },
    { value: 'phone', name: strings.common.phone },
  ];

  const onDetail = id => history.push(`/payment/${id}`);

  const getFilterOption = () => {
    return {
      column: $('#searchColumn').val(),
      keyword: $('#searchInput').val(),
    }
  }

  const onDownload = () => {
    context.get(
      'payment/list',
      {
        start: 0,
        length: 0,
        ...getFilterOption(),
        download: true
      },
      response => {
        const excelData = [];
        response.forEach(it => {
          excelData.push([
            it.id,
            it.user_name,
            it.level_name,
            dayjs(it.start_date).format(FORMAT.DATE),
            it.code == '' ? '-' : it.code,
            it.price.currencyFormat(),
            it.discount == '' ? '-' : it.discount.currencyFormat(),
            it.create_date
          ]);
        });

        context.downloadExcel(`payment-${dayjs().format(FORMAT.DATE)}.csv`, [
          [
            '#',
            strings.facility.member_name,
            strings.payment.level_name,
            strings.payment.start_date,
            strings.payment.code,
            strings.payment.price,
            strings.payment.discount,
            strings.common.create_date,
          ],
          ...excelData
        ]);
      }
    );
  };

  return (
    <Container title={strings.menu.payment}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => refreshTable(true)}>
              {strings.common.search}
            </button>
            <button type='button' className='btn btn-info btn-sm ml-md-1' onClick={onDownload}>
              {strings.common.download}
            </button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'user_name', orderable: true,
              }, {
                targets: [columnNo++], data: 'level_name', orderable: false,
              }, {
                targets: [columnNo++], data: 'start_date', orderable: true,
                render: value => dayjs(value).format(FORMAT.DATE)
              }, {
                targets: [columnNo++], data: 'code', orderable: false,
                render: value => value == '' ? '-' : value
              }, {
                targets: [columnNo++], data: 'price', orderable: true,
                render: value => value.currencyFormat()
              }, {
                targets: [columnNo++], data: 'discount', orderable: true,
                render: value => value == 0 ? '-' : value.currencyFormat()
              }, {
                targets: [columnNo++], data: 'create_date', orderable: true,
              }],
              fnCreatedRow: function (row, item, index) {
                  $('td:eq(0)', row).html((index + 1));
              },
              order: [[0, 'DESC']],
              ajax: {
                url: 'payment/list',
                data: (req) => {
                  Object.assign(req, getFilterOption());
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.facility.member_name}</th>
              <th>{strings.payment.level_name}</th>
              <th>{strings.payment.start_date}</th>
              <th>{strings.payment.code}</th>
              <th>{strings.payment.price}</th>
              <th>{strings.payment.discount}</th>
              <th>{strings.common.create_date}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
    </Container>
  );

}

export default withRouter(Payment);
