import Container from "../../components/control/container";
import strings from "../../lang/strings";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { PageSettings } from "../../config/page-settings";
import { withRouter } from "react-router-dom";
import ImageSelect from "../../components/control/image-select";
import { rootStore } from "../../mobx/store";

const PushDetail = ({ id, history }) => {

  const context = useContext(PageSettings);

  const areaList = context.getSettingList('area');

  const [info, setInfo] = useState(null);
  const [file, setFile] = useState(null);

  // 조건에 맞는 전체 멤버수
  const [memberCount, setMemberCount] = useState(0);

  useEffect(() => {
    if (id < 1) {
      setInfo(null);
      return;
    }

    context.get(
      `support/push/${id}`,
      {},
      res => setInfo(res)
    );
  }, []);

  useEffect(() => {
    context.get(
      `support/push/member/count`,
      {
        gender: info?.gender ?? '',
        age: info?.age ?? '',
        level: info?.level ?? '',
        area: info?.area ?? '',
        category: info?.category ?? '',
        ticket_from: info?.ticket_from ?? '',
        ticket_to: info?.ticket_to ?? ''
      },
      res => setMemberCount(res),
      false
    );
  }, [info?.gender, info?.age, info?.level, info?.category, info?.area, info?.ticket_from, info?.ticket_to]);

  const onMultiCheck = (field, value) => {
    if (typeof value == "boolean") {
      // 전체 선택한 경우
      switch (field) {
        case 'age':
          setInfo({ ...info, [field]: value ? context.getSettingList(field).join(',') : '' });
          break;
        case 'level':
          setInfo({ ...info, [field]: value ? context.getSettingList(field).map(it => it.id).join(',') : '' });
          break;
        case 'category':
          setInfo({ ...info, [field]: value ? context.getSettingList(field).map(it => it.id).join(',') : '' });
          break;
        default:
      }
    } else {
      let values = info?.[field]?.split(',')?.map(it => `${it}`) ?? [];
      if (values.includes(`${value}`)) {
        values = values.filter(it => `${it}` != `${value}`);
      } else {
        values.push(value);
      }
      setInfo({ ...info, [field]: values.filter(it => it != '').join(',') });
    }
  };

  const onImageChange = e => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setInfo({
        ...info,
        file: reader.result
      });
      setFile(file);
    };
    reader.readAsDataURL(file);
  };

  const onImageDelete = () => {
    if (file != null) {
      setFile(null);
    }

    setInfo({
      ...info,
      file: ''
    });
  };

  const uploadImage = () => {
    if (file == null) {
      return '';
    }

    return new Promise(resolve => {
      let formData = new FormData();
      formData.append('file', file, file.name);

      context.post(
        'upload/image/push',
        formData,
        response => {
          resolve(response.image);
        }
      );
    });
  }

  const onAreaSelect = (checked, parent_id) => {
    const selectedArea = areaList.find(it => it.id == parent_id);
    setInfo(prev => {
      const prevAreaWithoutSelectedParentChildren = (prev?.area?.split(',') ?? []).filter(it => !selectedArea.children.some(t => t.id == it));
      return {
        ...prev,
        area: (checked ?
          prevAreaWithoutSelectedParentChildren.concat(selectedArea.children.map(it => it.id)) :
          prevAreaWithoutSelectedParentChildren
        ).join(',')
      }
    });
  }

  const onSave = e => {
    e.preventDefault();

    if (memberCount == 0) {
      context.showAlert(strings.push_history.send_no_member);
      return;
    }
    if ((info?.type ?? '') == '') {
      context.showAlert(strings.push_history.select_type);
      return;
    }

    context.showConfirm(strings.modal.save_confirm, async res => {
      if (res !== true) {
        return;
      }

      let image = info?.file || '';
      if (file != null) {
        image = await uploadImage();
      }

      context.post(
        `support/push/${id}`,
        {
          type: info?.type || Object.keys(strings.push_history.push_type)[0],
          gender: info?.gender || '',
          age: info?.age || '',
          category: info?.category || '',
          area: info?.area || '',
          level: info?.level || '',
          ticket_from: info?.ticket_from || '',
          ticket_to: info?.ticket_to || '',
          title: info?.title || '',
          content: info?.content || '',
          file: image,
          member_count: memberCount
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          history.goBack();
        }
      );
    });
  }

  return (
    <Container title={strings.menu.push}>
      <form className='form-horizontal form-bordered' onSubmit={onSave}>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.common.type}</label>
          <div className='col-xs-6 form-inline'>
            {
              Object.keys(strings.push_history.push_type).map((type, idx) => (
                <label key={idx} className='control-label m-r-20'>
                  <input type='radio' name='type'
                    checked={info?.type == type}
                    onChange={() => setInfo({ ...info, type })}
                    value={type} />&nbsp;{strings.push_history.push_type[type]}
                </label>
              ))
            }
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.common.gender}</label>
          <div className='col-xs-6 form-inline'>
            {
              Object.keys(strings.push_history.gender).map((gender, idx) => (
                <label key={idx} className='control-label m-r-20'>
                  <input type='checkbox' name='gender'
                    checked={info?.gender?.indexOf(gender) >= 0}
                    onChange={() => onMultiCheck('gender', gender)}
                    value={gender} />&nbsp;{strings.push_history.gender[gender]}
                </label>
              ))
            }
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.common.age}</label>
          <div className="col-xs-6">
            <label className='control-label m-b-10 form-inline'>
              <input type='checkbox' name='age'
                checked={info?.age?.split(',')?.length == context.getSettingList('age').length}
                onChange={(e) => onMultiCheck('age', e.target.checked)}
              />&nbsp;{strings.common.select_all}
            </label>
            <div className='form-inline'>
              {
                context.getSettingList('age').map((age, idx) => (
                  <label key={idx} className='control-label m-r-20'>
                    <input type='checkbox' name='age'
                      checked={info?.age?.split(',')?.includes(`${age}`) ?? false}
                      onChange={() => onMultiCheck('age', age)}
                      value={age} />&nbsp;{strings.formatString(strings.push_history.age, age)}
                  </label>
                ))
              }
            </div>
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.common.area}</label>
          <div className="col-xs-6">
            <table className='table table-bordered mb-0'>
              <tbody>
                {
                  areaList.map((parent, idx) => (
                    <Fragment key={idx}>
                      <tr>
                        <td rowSpan={2}>{parent.name}</td>
                        <td>
                          <label className='control-label form-inline mb-0'>
                            <input type='checkbox' name='category'
                              checked={parent.children.every(it => info?.area?.includes(it.id))}
                              onChange={e => onAreaSelect(e.target.checked, parent.id)}
                            />&nbsp;{strings.common.select_all}
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td className='text-left'>
                          {
                            parent.children.map((child, childIdx) => (
                              <label key={childIdx} className='control-label mb-0 m-r-10'>
                                <input
                                  type='checkbox' name='category' checked={info?.area?.split(',')?.includes(`${child.id}`)}
                                  onChange={() => {
                                    setInfo(prev => {
                                      const prevArea = prev?.area?.split(',') ?? [];
                                      return {
                                        ...prev,
                                        area: prevArea.includes(`${child.id}`) ?
                                          prevArea.filter(it => it != child.id).join(',') :
                                          prevArea.concat(child.id).join(',')
                                      }
                                    });
                                  }} />&nbsp;{child.name}
                              </label>
                            ))
                          }
                        </td>
                      </tr>
                    </Fragment>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
        {
          (rootStore.getMe?.facility ?? 0) == 0 &&
          <div className='form-group row'>
            <label className='col-xs-2 col-form-label'>{strings.facility.rating}</label>
            <div className="col-xs-6">
              <label className='control-label m-b-10 form-inline'>
                <input type='checkbox' name='level'
                  checked={info?.level?.split(',')?.length == context.getSettingList('level').length}
                  onChange={(e) => onMultiCheck('level', e.target.checked)}
                />&nbsp;{strings.common.select_all}
              </label>
              <div className='form-inline'>
                {
                  context.getSettingList('level').map((level, idx) => (
                    <label key={idx} className='control-label m-r-20'>
                      <input type='checkbox' name='level'
                        checked={info?.level?.split(',')?.includes(`${level.id}`) ?? false}
                        onChange={() => onMultiCheck('level', level.id)}
                        value={level.id} />&nbsp;{level.name}
                    </label>
                  ))
                }
              </div>
            </div>
          </div>
        }
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.member.interest_category}</label>
          <div className="col-xs-6">
            <label className='control-label m-b-10 form-inline'>
              <input type='checkbox' name='category'
                checked={info?.category?.split(',')?.length == context.getSettingList('category').length}
                onChange={(e) => onMultiCheck('category', e.target.checked)}
              />&nbsp;{strings.common.select_all}
            </label>
            <div className='form-inline'>
              {
                context.getSettingList('category').map((category, idx) => (
                  <label key={idx} className='control-label m-r-20'>
                    <input type='checkbox' name='category'
                      checked={info?.category?.indexOf(`${category.id}`) >= 0}
                      onChange={() => onMultiCheck('category', category.id)}
                      value={category.id} />&nbsp;{category.name}
                  </label>
                ))
              }
            </div>
          </div>
        </div>
        {
          (rootStore.getMe?.facility ?? 0) == 0 &&
          <div className='form-group row'>
            <label className='col-xs-2 col-form-label'>{strings.push_history.remain_ticket}</label>
            <div className="col-xs-6 form-inline">
              <input type='text' autoComplete='off' className='form-control'
                value={info?.ticket_from || ''}
                onChange={e => setInfo({ ...info, ticket_from: e.target.value })} />
              <label className='col-form-label'>&nbsp;~&nbsp;</label>
              <input type='text' autoComplete='off' className='form-control'
                value={info?.ticket_to || ''}
                onChange={e => setInfo({ ...info, ticket_to: e.target.value })} />
            </div>
          </div>
        }
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.push_history.content}</label>
          <div className="col-xs-6">
            <textarea
              className='form-control'
              rows={20}
              required
              value={info?.content || ''}
              placeholder="합티서비스에 맞는 혜택 내용을 적어주셔야 합니다. 합티 서비스와 맞지않는 내용이면, 보내실수 없습니다.
              (자체 할인혜택/이벤트 및 합티사용자에게 직접 시설 회원가입을 유도하는 문구 등)"
              onChange={e => setInfo({ ...info, content: e.target.value })} />
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.push_history.attachment}</label>
          <div className="col-xs-6">
            <ImageSelect
              width='20%'
              height='20%'
              image_url={info?.file?.getFullUrl()}
              onSelect={onImageChange}
              onDelete={onImageDelete} />
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.push_history.summary}</label>
          <div className="col-xs-6">
            <input type='text' autoComplete='off' className='form-control'
              value={info?.title || ''} required
              onChange={e => setInfo({ ...info, title: e.target.value })} />
          </div>
        </div>
        <div className='form-group row m-t-20'>
          <button type='button' className='btn btn-default col-xs-2' onClick={() => history.goBack()}>
            {strings.common.back}
          </button>
          <button type='submit' className='btn btn-primary col-xs-2 m-l-15'>
            {strings.formatString(strings.push_history.send_push, memberCount)}
          </button>
        </div>
      </form>
    </Container>
  );

}

export default withRouter(PushDetail);
