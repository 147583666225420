import React, { useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import ImageSelect from "../../../components/control/image-select";
import ReactQuill from "react-quill";

const NoticePopup = ({ isOpen, id, onClose }) => {

  const context = useContext(PageSettings);

  const [info, setInfo] = useState(null);
  const [file, setFile] = useState(null);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      // ['link', 'image'],
      ['image'],
      ['clean']
    ],
  }

  const getInfo = () => {
    if (id < 1) {
      setInfo(null);
      return;
    }

    context.get(
      `support/notice/${id}`,
      {},
      res => {
        setInfo(res);
      }
    );
  }

  const onImageChange = e => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setInfo({
        ...info,
        file: reader.result
      });
      setFile(file);
    };
    reader.readAsDataURL(file);
  };

  const onImageDelete = () => {
    if (file != null) {
      setFile(null);
    }

    setInfo({
      ...info,
      file: ''
    });
  };

  const uploadImage = () => {
    if (file == null) {
      return '';
    }

    return new Promise(resolve => {
      let formData = new FormData();
      formData.append('file', file, file.name);

      context.post(
        'upload/image/notice',
        formData,
        response => {
          resolve(response.image);
        }
      );
    });
  }

  const onSave = e => {
    e.preventDefault();

    context.showConfirm(strings.modal.save_confirm, async res => {
      if (res !== true) {
        return;
      }

      let image = info?.file || '';
      if (file != null) {
        image = await uploadImage();
      }

      context.post(
        `support/notice/${id}`,
        {
          type: info?.type || Object.keys(strings.post.notice_type)[0],
          title: info?.title || '',
          content: info?.content || '',
          file: image,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          onClose(true);
        }
      );
    });
  }

  return (
    <Modal size={'lg'} isOpen={isOpen} toggle={onClose} onOpened={getInfo}>
      <form onSubmit={onSave}>
        <ModalHeader className="popup-nickname" toggle={onClose}>
          {strings.post.notice_save}
        </ModalHeader>
        <ModalBody>
          <div className='row'>
            <label className='col-xs-2 col-form-label'>{strings.common.type}</label>
            <div className="col-xs-9">
              <select
                required
                className='form-control'
                value={info?.type || Object.keys(strings.post.notice_type)[0]}
                onChange={e => setInfo({ ...info, type: e.target.value })}>
                {
                  Object.keys(strings.post.notice_type).map(value => (
                    <option key={value} value={value}>{strings.post.notice_type[value]}</option>
                  ))
                }
              </select>
            </div>
          </div>
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{strings.common.title}</label>
            <div className="col-xs-9">
              <input type='text' autoComplete='off' className='form-control'
                     value={info?.title || ''} required
                     onChange={e => setInfo({ ...info, title: e.target.value })} />
            </div>
          </div>
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{strings.common.content}</label>
            <div className='col-xs-9'>
              <ReactQuill
                theme='snow'
                modules={modules}
                className=''
                value={info?.content || ''}
                onChange={content => {
                  setInfo(prev => {
                    return {
                      ...prev,
                      content: content
                    }
                  });
                }} />
              {/*<textarea
                className='form-control'
                rows={20}
                value={info?.content || ''}
                onChange={e => setInfo({ ...info, content: e.target.value })} />*/}
            </div>
          </div>
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{strings.common.image}</label>
            <div className='col-xs-9'>
              <ImageSelect
                width='20%'
                height='20%'
                image_url={info?.file?.getFullUrl()}
                onSelect={onImageChange}
                onDelete={onImageDelete} />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-default" type='button' onClick={onClose}>{strings.modal.close}</button>
          <button className="btn btn-success" type='submit'>{strings.common.save}</button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default NoticePopup;
