export default {
  admin: '합티 관리자',
  menu: {
    dashboard: '대시보드',
    member: '회원관리',
    facility_member: '시설 회원관리',
    facility: '제휴 시설 관리',
    my_facility: '내 시설 관리',
    trainer: '트레이너 관리',
    affiliate: '제휴시설 요청 / 관리',
    payment: '결제내역',
    category: '카테고리 수정',
    push: '푸쉬 관리',
    post: '게시글 관리',
    reservation: '예약 관리',
    review: '리뷰 관리',
    change_device: '디바이스 변경요청 관리',
    change_password: '비밀번호 변경',
    log_out: '로그아웃',
  },
  modal: {
    alert: '알림',
    confirm: '확인',
    ok: '예',
    no: '아니요',
    cancel: '취소',
    close: '닫기',
    apply: '적용',
    save_confirm: '저장하시겠습니까?',
    delete_confirm: '삭제하시겠습니까?',
  },
  msg: {
    applied: '적용되었습니다.',
    saved: '저장되었습니다.',
    deleted: '삭제되었습니다.'
  },
  common: {
    all: '전체',
    save: '저장',
    delete: '삭제',
    edit: '수정',
    add: '추가',
    search: '검색',
    name: '제목',
    nickname: '닉네임',
    gender: '성별',
    male: '남자',
    female: '여자',
    manage: '관리',
    title: '제목',
    content: '내용',
    image: '이미지',
    status: '상태',
    create_date: '생성일',
    update_date: '수정일',
    download: '엑셀 다운로드',
    write: '작성하기',
    view: '보기',
    show: 'show',
    hide: 'hide',
    list: '목록',
    type: '유형',
    total: '전체',
    year: '년',
    month: '월',
    date: '일',
    unknown: '모름',
    normal: '정상',
    gender: '성별',
    age: '연령',
    area: '지역',
    back: '뒤로가기',
    no_data: '데이터가 없습니다.',
    select_all: '전체선택',
    phone: '연락처',
    keyword: '검색'
  },
  login: {
    id: '아이디를 입력하세요',
    password: '비밀번호를 입력하세요',
    login: '로그인',
  },
  change_password: {
    current_password: '현재 비밀번호',
    new_password: '새 비밀번호',
    confirm_password: '비밀번호 확인',
    change: '변경',
    password_not_correct: '새 비밀번호와 비밀번호 확인이 동일하지 않습니다.',
  },
  dashboard: {
    new_members: '신규 회원 수',
    see_more: '더보기',
    facilities: '시설 수 : 전체 {0}건',
    push_history: '푸쉬 알림 내역',
    affiliate_request: '제휴 요청 수 ({0})'
  },
  facility: {
    detail: '시설 상세',
    name: '시설명',
    category: '시설 구분',
    rating: '시설 등급',
    reg_date: '등록일자',
    address: '주소',
    contact_info: '담당자 연락처',
    visitor_number: '선택된 기간 내 방문자 수',
    add: '시설 추가',
    visit_date: '방문일자',
    main_info: '시설 정보',
    visitor: '방문 회원 정보',
    member_name: '회원 이름',
    member_level: '회원 등급',
    contact: '연락처',
    id: 'ID',
    date_list: [
      '오늘',
      '어제',
      '이번 달',
      '이번 주',
      '지난 30일',
      '지난 7일',
      '지난 달',
      '지난 주',
      '작년',
    ],
    main_image: '대표 이미지',
    work_hour_holiday: '업무 시간 및 휴무일',
    work_hour: '업무 시간',
    weekend_work_hour: '주말 업무 시간',
    holidays: {
      W: '주말',
      H: '공휴일',
      A: '대체공휴일',
      S: '휴일선택'
    },
    additional: '추가 서비스',
    map_api: '네이버 지도 API',
    client_id: 'Client ID',
    client_secret: 'Client Secret',
    google_map_api: '구글 지도 API',
    api_key: 'API 키',
    map_type: '지도 종류',
    map: '네이버 지도',
    google_map: '구글 지도',
    select_category: '카테고리를 선택하세요',
    select_area: '지역을 선택하세요',
    select_image: '대표이미지를 선택하세요.',
    input_service: '서비스를 추가하세요.',
    recommend: '추천',
    admin: '시설관리자 아이디',
    input_password: '비밀번호를 입력하세요',
    confirm_password: '비밀번호가 동일하지 않습니다.',
  },
  trainer: {
    detail: '트레이너 상세',
    add: '트레이너 추가',
  },
  member: {
    detail: '회원 상세',
    interest_category: '관심 종목',
    interest_area: '관심 지역',
    main_info: '회원 기본 정보',
    visit_log: '방문한 시설 정보',
    email: '이메일',
    reset_pwd: '비밀번호 초기화',
    sign_out: '회원 탈퇴',
    this_month_remain: '이번 달 잔여 이용권',
    remain_gift: '잔여 선물 횟수',
    last_month_remain: '지난 달 잔여 이용권',
    avg_remain: '평균 잔여 이용권',
    join_date: '회원 가입 일',
    membership: '멤버쉽',
    membership_date: '멤버쉽 가입 일',
    sign_out_confirm: '탈퇴처리 하시겠습니까?',
    reset_pwd_confirm: '비밀번호를 초기화 하시겠습니까?'
  },
  category: {
    category: '관심종목 카테고리 ',
    category_save: '관심종목 카테고리 등록/수정',
    rating: '회원/시설 등급 카테고리',
    rating_save: '회원/시설 등급 카테고리 등록/수정',
    price: '가격',
    gift: '선물'
  },
  push_history: {
    type: '푸시 구분',
    summary: '내용 요약',
    receive_group: '수신그룹',
    issue_date: '발행 일자',
    date_from: '시작일',
    date_to: '종료일',
    remain_ticket: '잔여 이용권 수',
    content: '발송 내용',
    attachment: '첨부파일',
    send_push: '푸시 알림 보내기 ({0})',
    select_type: '유형을 선택하세요',
    send_no_member: '수신할 회원이 없습니다.',
    push_type: {
      'E': '이메일',
      'P': '푸시 알림'
    },
    age: '{0}대',
    gender: {
      'M': '남성',
      'F': '여성'
    }
  },
  payment: {
    detail: '결제상세',
    level_name: '등급명',
    start_date: '결제일',
    code: '쿠폰코드',
    price: '금액',
    discount: '할인금액',
  },
  affiliate_request: {
    contents: '내용',
    detail: '제휴 요청 상세',
    received_date: '수신 일자',
    read: '읽음',
    unread: '읽지않음',
    memo: '메모'
  },
  post: {
    faq: '자주묻는 질문',
    faq_save: '자주묻는 질문 등록/수정',
    faq_type: {
      'S': '회원가입 문제',
      'P': '제휴 문제',
      'M': '멤버쉽 가입 문제',
    },
    notice: '공지사항',
    notice_save: '공지사항 등록/수정',
    notice_type: {
      'N': '공지사항',
      'E': '이벤트'
    },
    file: '첨부파일',
    event: '이벤트'
  },
  reservation: {
    detail: '예약 상세',
    day_list: ['일', '월', '화', '수', '목', '금', '토'],
    not_select_prev_date: '오늘 이후 날짜를 선택하세요',
    date: '예약일',
    time: '예약시간',
    visit_count: '방문회수',
    pending: '신청',
    confirm: '승인',
    confirm_msg: '처리하시겠습니까?',
    deny: '거절',
    no_content: '내역이 없습니다.'
  }
}
