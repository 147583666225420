import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';
import Container from "../../../components/control/container";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";

const FacilityFragment = () => {

  const context = useContext(PageSettings);

  const [totalCount, setTotalCount] = useState(0);
  const [list, setList] = useState([]);

  useEffect(() => {
    context.get(
      'dashboard/facility',
      {},
      response => {
        setTotalCount(response.totalCount);
        setList(response.list);
      }
    );
  }, []);

  return (
    <Container title={strings.formatString(strings.dashboard.facilities, totalCount.currencyFormat())} rightButton={(
      <Link to="/facility" style={{ color: '#fff' }}>
        {strings.dashboard.see_more} <i className="fa fa-arrow-alt-circle-right" />
      </Link>
    )}>
      <Table>
        <thead>
        <tr>
          <th>{strings.facility.name}</th>
          <th>{strings.facility.category}</th>
          <th>{strings.facility.rating}</th>
          <th>{strings.facility.reg_date}</th>
        </tr>
        </thead>
        <tbody>
        {
          list.length < 1 ?
            <tr>
              <td colSpan={4}>{strings.common.no_data}</td>
            </tr>
            :
            list.map((tr, idx) => (
              <tr key={idx}>
                <td>{tr.name}</td>
                <td>{context.getSettingList('category').filter(it => tr.category.split(',').includes(`${it.id}`)).map(it => it.name).join(', ') || '-'}</td>
                <td>{context.getSettingList('level').find(it => it.id == tr.level)?.name || '-'}</td>
                <td>{tr.create_date}</td>
              </tr>
            ))
        }
        </tbody>
      </Table>
    </Container>
  );
}

export default FacilityFragment;
