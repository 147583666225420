import Container from "../../components/control/container";
import strings from "../../lang/strings";
import { PageSettings } from "../../config/page-settings";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import $ from "jquery";
import { useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";

const Trainer = ({ history }) => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => tableRef.current?.draw(refresh);

  const searchOptions = [
    { value: 'name', name: '이름' },
  ];

  const onDetail = id => history.push(`/trainer/${id}`);

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        `trainer/${id}`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <Container title={strings.menu.trainer}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-group row form-inline'>
            <label className='col-md-1 col-form-label'>{strings.common.keyword}</label>
            <div className='col-md-10'>
              <div className='form-inline'>
                <SearchSelect
                  id='searchColumn'
                  value={searchOptions[0].value}
                  data={searchOptions}
                  onChange={() => refreshTable(true)} />
                <SearchInput
                  id='searchInput'
                  action={() => refreshTable(true)} />
                <button type='button' className='btn btn-success btn-sm ml-md-5' onClick={() => refreshTable(true)}>
                  {strings.common.search}
                </button>
                <button type='button' className='btn btn-primary btn-sm ml-md-4'
                    onClick={() => history.push(`/trainer/0`)}>
                  {strings.trainer.add}
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr className='mt-3' />
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'name', orderable: true,
              }, {
                targets: [columnNo++], data: 'image', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<img src='${value.getFullUrl()}' style='width: 100px;height: 100px;object-fit: cover;' />`);
                }
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<button type='button' class='btn btn-outline-success btn-sm m-2'>${strings.modal.confirm}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onDetail(item.id))
                    .end()
                    .append(`<button type='button' class='btn btn-outline-danger btn-sm m-2'>${strings.common.delete}</button>`)
                    .find('button:eq(1)')
                    .on('click', () => onDelete(item.id));
                }
              }],
              order: [[0, 'DESC']],
              ajax: {
                url: 'trainer/list',
                data: (req) => {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
              <tr>
                <th>#</th>
                <th>{'강사 이름'}</th>
                <th>{'강사 프로필'}</th>
                <th>{strings.common.manage}</th>
              </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
    </Container>
  );

}

export default withRouter(Trainer);
