import Container from "../../components/control/container";
import strings from "../../lang/strings";
import { useContext, useEffect, useRef, useState } from "react";
import { PageSettings } from "../../config/page-settings";
import { withRouter } from "react-router-dom";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import $ from "jquery";
import DateTime from 'react-datetime';
import { FORMAT } from "../../config/const";
import dayjs from "dayjs";
import { rootStore } from "../../mobx/store";

const Push = ({ history }) => {

  const context = useContext(PageSettings);

  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const fromRef = useRef('');
  const toRef = useRef('');

  useEffect(() => {
    fromRef.current = from;
  }, [from]);
  useEffect(() => {
    toRef.current = to;
  }, [to]);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => tableRef.current?.draw(refresh);

  const searchOptions = [
    { value: 'content', name: strings.common.content },
  ];
  const pushType = [
    { value: '', name: strings.common.all }
  ];
  for (let value in strings.push_history.push_type) {
    pushType.push({ value, name: strings.push_history.push_type[value] });
  }

  const onDetail = id => history.push(`/push/${id}`);

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        `support/push/${id}`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  const getFilterOption = () => {
    return {
      from: fromRef.current,
      to: toRef.current,
      type: $('#searchType').val(),
      column: $('#searchColumn').val(),
      keyword: $('#searchInput').val(),
      facility: rootStore.getMe?.facility ?? 0,
    };
  }

  const onDownload = () => {
    context.get(
      'support/push',
      {
        start: 0,
        length: 0,
        ...getFilterOption(),
        download: true
      },
      response => {
        const excelData = [];
        response.forEach(it => {
          excelData.push([
            it.id,
            strings.push_history.push_type[it.type] || '',
            it.content.replace(/\n/g, ' '),
            it.member_count,
            it.create_date,
          ]);
        });

        context.downloadExcel(`push-${dayjs().format(FORMAT.DATE)}.csv`, [
          [
            '#',
            strings.push_history.type,
            strings.common.content,
            strings.push_history.receive_group,
            strings.push_history.issue_date,
          ],
          ...excelData
        ]);
      }
    );
  };

  return (
    <Container title={strings.menu.push}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <DateTime
              dateFormat={FORMAT.DATE}
              timeFormat={false}
              inputProps={{ placeholder: strings.push_history.date_from }}
              defaultValue={from}
              closeOnSelect={true}
              className="ml-md-2"
              onChange={(e) => {
                if (typeof e === 'string') {
                  setFrom(e);
                } else {
                  setFrom(e.format(FORMAT.DATE));
                }
              }} />
            <label className='control-label'>&nbsp;~</label>
            <DateTime
              dateFormat={FORMAT.DATE}
              timeFormat={false}
              inputProps={{ placeholder: strings.push_history.date_to }}
              defaultValue={to}
              closeOnSelect={true}
              className="ml-md-1 mr-4"
              onChange={(e) => {
                if (typeof e === 'string') {
                  setTo(e);
                } else {
                  setTo(e.format(FORMAT.DATE));
                }
              }} />
            <SearchSelect
              id='searchType'
              className='mr-2'
              value={pushType[0].value}
              data={pushType}
              onChange={() => refreshTable(true)} />
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => refreshTable(true)}>
              {strings.common.search}
            </button>
            <button type='button' className='btn btn-primary btn-sm ml-md-4' onClick={() => onDetail(0)}>
              {strings.common.write}
            </button>
            {
              (rootStore.getMe?.facility ?? 0) == 0 &&
              <button type='button' className='btn btn-info btn-sm ml-md-1' onClick={onDownload}>
                {strings.common.download}
              </button>
            }
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'type', orderable: true,
                render: value => strings.push_history.push_type[value] || ''
              }, {
                targets: [columnNo++], data: 'content', orderable: true,
              }, {
                targets: [columnNo++], data: 'member_count', orderable: false,
              }, {
                targets: [columnNo++], data: 'create_date', orderable: true,
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<button type='button' class='btn btn-outline-success btn-sm m-2'>${strings.modal.confirm}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onDetail(item.id))
                    .end()
                    .append(`<button type='button' class='btn btn-outline-danger btn-sm m-2'>${strings.common.delete}</button>`)
                    .find('button:eq(1)')
                    .on('click', () => onDelete(item.id));
                }
              }],
              fnCreatedRow: function (row, item, index) {
                  $('td:eq(0)', row).html((index + 1));
              },
              order: [[0, 'DESC']],
              ajax: {
                url: 'support/push',
                data: (req) => {
                  Object.assign(req, getFilterOption());
                }
              },
            }}>
            <thead>
              <tr>
                <th>#</th>
                <th>{strings.push_history.type}</th>
                <th style={{ width: '50%' }}>{strings.common.content}</th>
                <th>{strings.push_history.receive_group}</th>
                <th>{strings.push_history.issue_date}</th>
                <th>{strings.common.manage}</th>
              </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
    </Container>
  );

}

export default withRouter(Push);
