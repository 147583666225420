import React, { useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";

const MemberDetailPopup = ({ isOpen, info, onClose }) => {

  const context = useContext(PageSettings);

  const onConfirm = e => {
    e.preventDefault();

    context.post(
      `facility/visitor_confirm/${info.id}`,
      {},
      () => {
        onClose(true);
      }
    );
  }

  return (
    <Modal size={'lg'} isOpen={isOpen} toggle={onConfirm}>
      <ModalHeader className="popup-nickname" toggle={onConfirm}>
        {'방문자 확인'}
      </ModalHeader>
      <ModalBody>
        <div className='row'>
          <label className='col-xs-2 col-form-label'>{'프로필'}</label>
          <div className="col-xs-9" style={{ textAlign: 'center' }}>
            <img src={('' + info?.user_profile).getFullUrl()} style={{ width: 200, height: 200, objectFit: 'cover' }} />
          </div>
        </div>
        <div className='row mt-2'>
          <label className='col-xs-2 col-form-label'>{'이메일'}</label>
          <div className="col-xs-9">
            <input
              required
              type='text'
              className='form-control'
              disabled
              value={info?.user_email || ''} />
          </div>
        </div>
        <div className='row mt-2'>
          <label className='col-xs-2 col-form-label'>{'성별'}</label>
          <div className="col-xs-9">
            <input
              required
              type='text'
              className='form-control'
              disabled
              value={info?.user_gender || ''} />
          </div>
        </div>
        {
          info?.pay_type != '' &&
          <div className='row mt-2'>
          <label className='col-xs-2 col-form-label'>{'추가 결제 내역'}</label>
          <div className="col-xs-9">
            <input
              required
              type='text'
              className='form-control'
              disabled
              value={info?.pay_type == 'direct' ? '현장결제' : '카드결제'} />
            <input
              required
              type='text'
              className='form-control'
              disabled
              value={info?.price || 0} />
          </div>
        </div>
        }
      </ModalBody>
      <ModalFooter>
        {/* <button className="btn btn-default" type='button' onClick={onClose}>{strings.modal.close}</button> */}
        <button className="btn btn-success" type='button' onClick={onConfirm}>{strings.modal.confirm}</button>
      </ModalFooter>
    </Modal>
  );
};

export default MemberDetailPopup;
