import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';
import Container from "../../../components/control/container";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";

const PushLogFragment = () => {

  const context = useContext(PageSettings);

  const [list, setList] = useState([]);
  useEffect(() => {
    context.get(
      'dashboard/push',
      {},
      response => setList(response)
    );
  }, []);

  return (
    <Container title={strings.dashboard.push_history} rightButton={(
      <Link to="/push" style={{ color: '#fff' }}>
        {strings.dashboard.see_more} <i className="fa fa-arrow-alt-circle-right" />
      </Link>
    )}>
      <Table>
        <thead>
        <tr>
          <th>{strings.push_history.type}</th>
          <th>{strings.push_history.summary}</th>
          <th>{strings.push_history.receive_group}</th>
          <th>{strings.push_history.issue_date}</th>
        </tr>
        </thead>
        <tbody>
        {
          list.length < 1 ?
            <tr>
              <td colSpan={4}>{strings.common.no_data}</td>
            </tr>
            :
            list.map((tr, idx) => (
              <tr key={idx}>
                <td>{tr.type}</td>
                <td>{tr.content}</td>
                <td>{tr.member_count.currencyFormat()}</td>
                <td>{tr.create_date}</td>
              </tr>
            ))
        }
        </tbody>
      </Table>
    </Container>
  );
}

export default PushLogFragment;
