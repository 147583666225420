import { PageSettings } from "../../../config/page-settings";
import { Fragment, useContext, useEffect, useState } from "react";
import strings from "../../../lang/strings";
import ImageSelect from "../../../components/control/image-select";
import { withRouter } from "react-router-dom";
import { rootStore } from "../../../mobx/store";
import ReactQuill from "react-quill";

const TrainerInfoFragment = ({ id, history }) => {

  const context = useContext(PageSettings);

  const [info, setInfo] = useState(null);
  const [profile, setProfile] = useState({
    url: '',
    file: null
  }); // 프로필이미지
  const [ptList, setPTList] = useState([]);
  const [newPT, setNewPT] = useState({
    id: 0,
    name: '',
    intro: '',
    date: '',
  });


  const editorModules = {
    toolbar: [
      [{ 'size': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['clean']
    ],
  }

  const editorFormats = [
    'size',
    'bold', 'italic', 'underline', 'strike',
    'color',
    'list', 'bullet', 'indent',
  ]

  useEffect(() => {
    getTrainerInfo();
  }, []);

  const getTrainerInfo = () => {
    if (id > 0) {
      context.get(
        `trainer/detail/${id}`,
        {},
        response => {
          setInfo(response);
          setProfile({
            url: response.image,
            file: null
          });
        }
      );
    }
  }

  const onImageChange = e => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setProfile({ url: reader.result, file });
    };
    reader.readAsDataURL(file);
  };

  const onImageDelete = () => {
    if (profile.file != null) {
      setProfile({
        url: info?.image ?? '',
        file: null
      });
      return;
    }

    setInfo({
      ...info,
      image: ''
    });
    setProfile({
      url: '',
      file: null
    });
  };

  const uploadImage = () => {
    if (profile.file == null) {
      return profile.url;
    }

    return new Promise(resolve => {
      let formData = new FormData();
      formData.append('file', profile.file, profile.file.name);

      context.post(
        'upload/image/trainer',
        formData,
        response => {
          resolve(response.image);
        }
      );
    });
  }

  const onDeletePT = index => {
    setPTList(prev => prev.filter((it, idx) => idx != index));
  }

  const onAddPT = () => {
    if (newPT.name == '' || newPT.intro == '') {
      return;
    }
    setPTList(prev => prev.concat(newPT));
    setNewPT({
      id: 0,
      name: '',
      intro: '',
      date: ''
    });
  }

  const onPTChange = (index, field, value) => {
    if (index == 0) {
      setNewPT({
        ...newPT,
        [field]: value,
      });
    } else {
      setPTList(prev => prev.map((it, idx) => {
        if (idx == index) {
          it[field] = value;
        }
        return it;
      }));
    }
  }

  const onSave = e => {
    e.preventDefault();

    if ((info?.name ?? '') == '') {
      context.showAlert('이름을 입력해주세요.');
      return;
    }

    if ((info?.intro ?? '') == '') {
      context.showAlert('소개를 입력해주세요.');
      return;
    }

    if (profile.url == '') {
      context.showAlert('사진을 선택해주세요.');
      return;
    }

    if (ptList.length == 0) {
      context.showAlert('강습 정보를 설정하세요.');
      return;
    }

    context.showConfirm(strings.modal.save_confirm, async res => {
      if (res !== true) {
        return;
      }

      const image = await uploadImage();
      context.post(
        `trainer/save/${id}`,
        {
          facility: rootStore.getMe?.facility ?? 0,
          name: info?.name || '',
          intro: info?.intro || '',
          image: image,
          experience: info?.experience || '0',
          pt_info: JSON.stringify(ptList)
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          getTrainerInfo();
        }
      );
    });
  }

  return (
    <form className='form-horizontal form-bordered' onSubmit={onSave}>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'강사 이름'}</label>
        <div className="col-xs-6">
          <input type='text' autoComplete='off' className='form-control'
            value={info?.name || ''} required
            placeholder={"예: 홍길동PT팀장 , 홍길동 대표 , 홍길동트레이너 , 홍길동 강사 등"}
            onChange={e => setInfo({ ...info, name: e.target.value })} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'강사 소개'}</label>
        <div className="col-xs-6">
          <ReactQuill
            theme='snow'
            className=''
            modules={editorModules}
            formats={editorFormats}
            value={info?.intro || ''}
            onChange={content => {
              setInfo(prev => {
                return {
                  ...prev,
                  intro: content
                }
              });
            }} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'강사 사진'}</label>
        <div className="col-xs-6">
          <ImageSelect
            width='20%'
            height='20%'
            image_url={profile.url?.getFullUrl()}
            onSelect={onImageChange}
            onDelete={onImageDelete} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'체험수업'}</label>
        <div className="col-xs-6">
          <label className='control-label m-r-20'>
            <input
              type='checkbox' name='experience' checked={info?.experience == 1}
              onChange={() => {
                setInfo(prev => {
                  return {
                    ...prev,
                    experience: prev?.experience == 1 ? 0 : 1
                  };
                });
              }} />
            &nbsp;{'* 첫 방문 혜택'}
          </label>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'강습 정보'}</label>
        <div className="col-xs-6">
          {
            ptList.map((it, idx) => (
              <div key={idx} className='form-inline m-b-10'>
                <div className="col-xs-11 d-flex">
                  <label className='control-label col-md-4'>{'강습명'}</label>
                  <input type='text' className='form-control m-l-5' value={it.name} style={{ flex: 1 }}
                    placeholder="*강습명을 적어주세요.예:) 1:1PT, 1:1기구필라테스, 골프레슨 등"
                    onChange={e => onPTChange(idx, 'name', e.target.value)} />
                </div>
                <div className="col-xs-11 d-flex m-t-5">
                  <label className='control-label col-md-4'>{'강습 소개'}</label>
                  <input type='text' className='form-control m-l-5' value={it.intro} style={{ flex: 1 }}
                    placeholder="*강습을 간략하게 회원에게 소개해주세요."
                    onChange={e => onPTChange(idx, 'intro', e.target.value)} />
                </div>
                <button type='button' className='btn btn-icon' onClick={() => onDeletePT(idx)}>
                  <i className='fa fa-times' />
                </button>
              </div>
            ))
          }
          <div className='form-inline'>
            <div className="col-xs-11 d-flex">
              <label className='control-label col-md-4'>{'강습명'}</label>
              <input type='text' className='form-control m-l-5' value={newPT.name} style={{ flex: 1 }}
                placeholder="*강습명을 적어주세요. 예:) 1:1PT, 1:1기구필라테스, 골프레슨 등"
                onChange={e => onPTChange(0, 'name', e.target.value)} />
            </div>
            <div className="col-xs-11 d-flex m-t-5">
              <label className='control-label col-md-4'>{'강습 소개'}</label>
              <input type='text' className='form-control m-l-5' value={newPT.intro} style={{ flex: 1 }}
                placeholder="*강습을 간략하게 회원에게 소개해주세요."
                onChange={e => onPTChange(0, 'intro', e.target.value)} />
            </div>
            <button type='button' className='btn btn-icon' onClick={onAddPT}>
              <i className='fa fa-plus' />
            </button>
          </div>
        </div>
      </div>
      {
        info?.create_date &&
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{'등록일'}</label>
          <div className="col-xs-6">{info?.create_date}</div>
        </div>
      }
      <div className='form-group row m-t-20'>
        <button type='button' className='btn btn-default col-xs-2' onClick={() => history.push('/trainer')}>
          {strings.common.back}
        </button>
        <button type='submit' className='btn btn-primary col-xs-2 m-l-15'>
          {strings.common.save}
        </button>
      </div>
    </form>
  );

}

export default withRouter(TrainerInfoFragment);
