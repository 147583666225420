import React, { useContext } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";

const RequestDetailPopup = ({ isOpen, info, onClose }) => {

  const context = useContext(PageSettings);

  const onConfirm = e => {
    e.preventDefault();

    onClose(true);
  }

  return (
    <Modal size={'lg'} isOpen={isOpen} toggle={onConfirm}>
      <ModalHeader className="popup-nickname" toggle={onConfirm}>
        {'요청 상세'}
      </ModalHeader>
      <ModalBody>
        <div className='row'>
          <label className='col-xs-2 col-form-label'>{'전화번호'}</label>
          <div className="col-xs-9">
            <input
              required
              type='text'
              className='form-control'
              disabled
              value={info?.phone || ''} />
          </div>
        </div>
        <div className='row mt-2'>
          <label className='col-xs-2 col-form-label'>{'디바이스'}</label>
          <div className="col-xs-9">
            <input
              required
              type='text'
              className='form-control'
              disabled
              value={info?.device_id || ''} />
          </div>
        </div>
        <div className='row mt-2'>
          <label className='col-xs-2 col-form-label'>{'사유'}</label>
          <div className="col-xs-9">
            <input
              required
              type='text'
              className='form-control'
              disabled
              value={info?.reason || ''} />
          </div>
        </div>
        <div className='row mt-2'>
          <label className='col-xs-2 col-form-label'>{'상태'}</label>
          <div className="col-xs-9 col-form-label">
            {info?.status == 0 ? '요청' : info?.status == 1 ? '승인' : '거부'}
          </div>
        </div>
        <div className='row mt-2'>
          <label className='col-xs-2 col-form-label'>{'요청일'}</label>
          <div className="col-xs-9 col-form-label">
            {info?.create_date}
          </div>
        </div>
        {
          info?.status != 0 &&
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{'상태변경일'}</label>
            <div className="col-xs-9 col-form-label">
              {info?.approve_date}
            </div>
          </div>
        }
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-success" type='button' onClick={onConfirm}>{strings.modal.confirm}</button>
      </ModalFooter>
    </Modal>
  );
};

export default RequestDetailPopup;
