import NewMembersFragment from './fragment/new-members-fragment';
import AffiliateRequestFragment from './fragment/affiliate-request-fragment';
import ReviewFragment from './fragment/review-fragment';
import PushLogFragment from "./fragment/push-log-fragment";
import FacilityFragment from "./fragment/facility-fragment";
import { rootStore } from "../../mobx/store";
import ReservationFragment from './fragment/reservation-fragment';
import VisitorFragment from './fragment/visitor-fragment';

const Dashboard = () => {
  return (
    <>
      <div className='row'>
        <div className="col-xl-6">
          {
            (rootStore.getMe?.facility ?? 0) == 0 ?
              <NewMembersFragment />
              :
              <VisitorFragment />
          }
        </div>
        <div className="col-xl-6">
          {
            (rootStore.getMe?.facility ?? 0) == 0 ?
              <FacilityFragment />
              :
              <ReviewFragment />
          }
        </div>
      </div>
      <div className='row'>
        <div className="col-xl-6">
          <PushLogFragment />
        </div>
        <div className="col-xl-6">
          {
            (rootStore.getMe?.facility ?? 0) == 0 ?
              <AffiliateRequestFragment />
              :
              <ReservationFragment />
          }
        </div>
      </div>
    </>
  );
}

export default Dashboard;
