import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import strings from "../../../lang/strings";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";

const AffiliateRequestFragment = () => {

  const context = useContext(PageSettings);

  const [totalCount, setTotalCount] = useState(0);
  const [list, setList] = useState([]);

  useEffect(() => {
    context.get(
      'dashboard/affiliate',
      {},
      response => {
        setTotalCount(response.totalCount);
        setList(response.list);
      }
    );
  }, []);

  return (
    <Container
      title={strings.formatString(strings.dashboard.affiliate_request, totalCount.currencyFormat())}
      rightButton={(
        <Link to="/affiliate" style={{ color: '#fff' }}>
          {strings.dashboard.see_more} <i className="fa fa-arrow-alt-circle-right" />
        </Link>
      )}>
      <Table>
        <thead>
        <tr>
          <th>{strings.facility.name}</th>
          <th>{strings.facility.category}</th>
          <th>{strings.facility.address}</th>
          <th>{strings.common.status}</th>
          <th>{strings.affiliate_request.contents}</th>
        </tr>
        </thead>
        <tbody>
        {
          list.length < 1 ?
            <tr>
              <td colSpan={5}>{strings.common.no_data}</td>
            </tr>
            :
            list.map((tr, idx) => (
              <tr key={idx}>
                <td>{tr.name}</td>
                <td>{context.getSettingList('category').find(it => it.id == tr.category)?.name || '-'}</td>
                <td>{context.getSettingList('area').find(it => it.id == tr.area)?.name || '-'}</td>
                <td>{tr.status == 0 ? strings.affiliate_request.unread : strings.affiliate_request.read}</td>
                <td>{tr.id}</td>
              </tr>
            ))
        }
        </tbody>
      </Table>
    </Container>
  );
}

export default AffiliateRequestFragment;
