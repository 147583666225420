import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import strings from "../../../lang/strings";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";

const ReservationFragment = () => {

  const context = useContext(PageSettings);

  const [totalCount, setTotalCount] = useState(0);
  const [list, setList] = useState([]);

  useEffect(() => {
    context.get(
      'dashboard/reservation',
      {},
      response => {
        setTotalCount(response.totalCount);
        setList(response.list);
      }
    );
  }, []);

  return (
    <Container
      title={'예약 관리'}
      rightButton={(
        <Link to="/reservation" style={{ color: '#fff' }}>
          {strings.dashboard.see_more} <i className="fa fa-arrow-alt-circle-right" />
        </Link>
      )}>
      <Table>
        <thead>
        <tr>
          <th>{'ID'}</th>
          <th>{'이름'}</th>
          <th>{'예약일'}</th>
          <th>{'예약시'}</th>
          <th>{'회원등급'}</th>
        </tr>
        </thead>
        <tbody>
        {
          list.length < 1 ?
            <tr>
              <td colSpan={5}>{strings.common.no_data}</td>
            </tr>
            :
            list.map((tr, idx) => (
              <tr key={idx}>
                <td>{tr.email}</td>
                <td>{tr.name}</td>
                <td>{tr.date}</td>
                <td>{tr.start_time}</td>
                <td>{tr.level_name}</td>
              </tr>
            ))
        }
        </tbody>
      </Table>
    </Container>
  );
}

export default ReservationFragment;
