module.exports = {
  isLocalhost: true,
  API_URL: 'https://api.ticketshare.co.kr', // this.isLocalhost ? 'http://192.168.0.54:3005' : 'http://1.234.63.213:3005' : 'https://api.ticketshare.co.kr',

  HEADER: {
    AUTH_TOKEN: 'x-access-token',
    CONTENT_TYPE: {
      NAME: 'Content-Type',
      JSON: 'application/json',
      FILE: 'multipart/form-data',
      FORM: 'application/x-www-form-urlencoded'
    },
    LANG: 'Accept-Language',
  },

  //. 각종 시간/일자 포맷
  FORMAT: {
    DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
    DATE: 'YYYY-MM-DD',
    TIME: 'HH:mm:ss',
    MONTH: 'YYYY-MM',
    YEAR: 'YYYY'
  },

  //. 응답코드
  RESULT_CODE: {
    SUCCESS: 0,                     //. 정상
    EMPTY_TOKEN: -1,                //. 토큰정보 없음.
    TOKEN_EXPIRED: -2,              //. 토큰 기한 만료
    EMPTY_PARAM: -3,                //. 요청파라미터 누락
    ERROR: -10,                     //. api 내부 오류
  },

  //. 사용자 상태코드
  USER_STATUS: {
    LEAVE: -2,                      //. 탈퇴
    BLOCK: -1,                      //. 영구정지
    NORMAL: 1,                      //. 정상
  },

  //. 성별
  GENDER: {
    MALE: 'M',
    FEMALE: 'F'
  },

  //. 상태코드
  STATUS: {
    PENDING: 0,         //. 대기
    NORMAL: 1,          //. 정상
    REJECT: 2,          //. 반려
    DELETED: -1,        //. 삭제
    FINISH: 2,          //. 완료
    CANCEL: 2,          //. 취소
    REAPPLY: 3,         //. 재심요청
    YES: 'Y',
    NO: 'N'
  },

  FACILITY_SERVICES: [
    {id: 1, name: '운동복'},
    {id: 2, name: '수건'},
    {id: 3, name: '샤워실'},
    {id: 4, name: '락커'},
    {id: 5, name: '와이파이'},
    {id: 6, name: '주차장'},
    {id: 7, name: '유료주차'},
    {id: 8, name: '체성분검사'},
    {id: 9, name: '체형분석'},
    {id: 10, name: '음료판매'},
    {id: 11, name: '반신욕'},
    {id: 12, name: '안마의자'},
    {id: 13, name: '사우나'},
    {id: 14, name: '찜질방'},
  ]
}
