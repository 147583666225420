import React, { useContext, useEffect, useRef, useState } from "react";
import { inject } from "mobx-react";
import { API_URL, HEADER } from "../../config/const";
import { PageSettings } from "../../config/page-settings";
import $ from 'jquery';
import ReactPaginate from 'react-paginate';
import 'datatables.net/js/jquery.dataTables.min';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';

const DataTable = React.forwardRef((props, ref) => {
  const {
    rootStore,
    hideTotalCount = false,
    customPaginate = false,
    options = {},
    initialPage = 0,
    onPageChange
  } = props;

  const context = useContext(PageSettings);
  const table = useRef();

  const [loading, toggleLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const config = {
    language: {
      oPaginate: {
        sFirst: "&lt;&lt;",
        sLast: "&gt;&gt;",
        sNext: "&gt;",
        sPrevious: "&lt;"
      },
      sInfo: "Total: _TOTAL_",
      sInfoEmpty: 'Total: 0',
      sThousands: ",",
      sLengthMenu: "Show _MENU_",
    },
    // dom: "<'row'<'col-md-6 col-sm-12'il><'col-md-6 col-sm-12'f>r>t<'row'p>",
    dom: "<'row'<'col-md-12 col-sm-12 d-flex justify-content-between'il><'col-md-6 col-sm-12'f>r>t<'row'p>",
    bAutoWidth: true,
    bFilter: false,
    bInfo: !hideTotalCount,
    bSort: true,
    bDestroy: true,
    bLengthChange: true,
    iDisplayLength: 10,
    aLengthMenu: [ 10, 30, 50, 100 ],
    bServerSide: true,
    bProcessing: false,
    bPaginate: !customPaginate,
    sPaginationType: 'full_numbers',
    order: [],
    columnDefs: [],
    ajax: {
      url: '',
      type: 'GET',
      headers: {
        [HEADER.AUTH_TOKEN]: rootStore.token,
        [HEADER.LANG]: 'ko'
      },
      beforeSend: () => {
        toggleLoading(true);
      },
      dataSrc: res => {
        toggleLoading(false);
        setTotalCount(res.recordsTotal);
        return res.data;
      },
      error: (err, xhr) => {
        toggleLoading(false);
        console.log('DataTable ajax error => ' + xhr + ', ' + JSON.stringify(err) + ', ' + err.message);
        context.showAlert('DataTable ajax error : ' + xhr);
      }
    },
    drawCallback: () => {
    },
    fnCreatedRow: (row, data, dataInd) => {
    }
  }

  useEffect(() => {
    $.extend(true, config, options);

    if (config.ajax && config.ajax.url) {
      config.ajax.url = API_URL + '/admin/' + config.ajax.url;
    } else {
      delete config.ajax;
      config.bServerSide = false;
    }

    ref.current = $(table?.current).DataTable(config);
  }, []);

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      {
        loading &&
        <div className="panel-loader"
             style={{ position: 'absolute', width: '100%', height: '200px' }}>
          <span className="spinner-small"/>
        </div>
      }
      <div/>
      <table
        className="table table-td-valign-middle table-th-valign-middle table-bordered table-search table-hover table-responsive-sm"
        ref={table}
        style={{ width: '100%' }}>
        {props.children}
      </table>
      {
        customPaginate &&
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
          <div className="row mt-2" style={{ justifyContent: "center" }}>
            <div className="dataTables_paginate paging_full_numbers">
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'paginate_button page-item disabled'}
                pageCount={Math.ceil(totalCount / 20)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                initialPage={initialPage / 20}
                onPageChange={(data) => onPageChange?.(data.selected * 20)}
                disableInitialCallback={true}
                containerClassName={'pagination'}
                pageClassName={'paginate_button page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'paginate_button page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'paginate_button page-item'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
                disabledClassName={'disabled'}
              />
            </div>
          </div>
        </div>
      }
    </div>
  );
})

export default inject('rootStore')(DataTable);
