import { PageSettings } from "../../../config/page-settings";
import { Fragment, useContext, useEffect, useState } from "react";
import strings from "../../../lang/strings";
import ImageSelect from "../../../components/control/image-select";
import { withRouter } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FACILITY_SERVICES, FORMAT } from "../../../config/const";
import DateTime from "react-datetime";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import { useDaumPostcodePopup } from "react-daum-postcode";

const FacilityInfoFragment = ({ id, affiliate, history }) => {

  const context = useContext(PageSettings);
  const { kakao } = window;

  const [memberList, setMemberList] = useState([]);
  const [admin, setAdmin] = useState(null);
  const [password, setPassword] = useState('');
  const [info, setInfo] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [newService, setNewService] = useState({
    id: 0,
    name: '',
    price: ''
  });
  const [snsList, setSNSList] = useState([]);
  const [newSNS, setNewSNS] = useState('');
  const [images, setImages] = useState([]);
  const geocoder = new kakao.maps.services.Geocoder();
  const postCodePopup = useDaumPostcodePopup();
  const [mapCenter, setMapCenter] = useState({
    lat: 37.570749, lng: 127.068233
  })

  const editorModules = {
    toolbar: [
      // [{ 'header': [1, 2, false] }],
      [{ 'size': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      // ['link', 'image'],
      ['image'],
      ['clean']
    ],
  }

  const editorFormats = [
    // 'header',
    'size',
    'bold', 'italic', 'underline', 'strike',
    'color',
    'list', 'bullet', 'indent',
    // 'link', 'image'
  ]

  useEffect(() => {
    getMemberList();
    getInfo();
  }, [id]);

  const getMemberList = () => {
    context.get(
      'member/list',
      {
        start: 0,
        length: 0,
        download: true
      },
      response => {
        setMemberList(response);
      }
    );
  }

  const getInfo = () => {
    if (id > 0) {
      context.get(
        `facility/detail/${id}`,
        {},
        response => {
          const { info, services, admin } = response;
          setInfo({
            ...info,
            category: info.category.split(',').filter(it => it != '').map(it => Number(it)),
            area: info.area.split(',').filter(it => it != '').map(it => Number(it)),
            service: info.service.split(',').filter(it => it != '').map(it => Number(it)),
            reservation_time: info.reservation_time.split(',').filter(it => it != '').map(it => it),
          });
          setServiceList(services);
          setSNSList(info.sns_url.split('|').filter(it => it != ''))
          setAdmin(admin);
          if (admin?.user_id) {
            setPassword(admin.password);
          }

          let photoList = [];
          info.photo.split(',').filter(it => it != '').forEach(it => {
            photoList.push({ url: it.getFullUrl(), file: null });
          });
          setImages(photoList);
        }
      );
    } else if (affiliate > 0) {
      context.get(
        `facility/affiliate/detail/${affiliate}`,
        {},
        response => {
          setInfo(prev => {
            return {
              ...prev,
              member: response.member,
              phone: response.phone,
              guide: response.memo,
              category: [response.category],
              area: context.getSettingList('area').find(it => it.id == response.area)?.children?.map(it => it.id) ?? []
            };
          });
        }
      );
    }
  }

  const onImageChange = (e, index) => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      if (index == 0) {
        setImages(prev => prev.concat({
          url: reader.result,
          file
        }));
        return;
      }
      setImages(prev => prev.map((it, idx) => {
        if (idx == index) {
          return {
            url: reader.result,
            file
          };
        }
        return it;
      }));
    };
    reader.readAsDataURL(file);
  };

  const onImageDelete = index => {
    setImages(prev => prev.filter((it, idx) => idx != index));
  };

  const uploadImage = () => {
    return new Promise(resolve => {
      let formData = new FormData();
      images.filter(it => it.file != null).forEach(image => {
        formData.append('file', image.file, image.file.name);
      });

      context.post(
        'upload/images/facility',
        formData,
        response => {
          resolve(response.image);
        }
      );
    });
  }

  const onDeleteAdditional = index => {
    setServiceList(prev => prev.filter((it, idx) => idx != index));
  }

  const onAddAdditional = () => {
    if (newService.name == '' || newService.price == '') {
      return;
    }
    setServiceList(prev => prev.concat(newService));
    setNewService({
      id: 0,
      name: '',
      price: ''
    });
  }

  const onAdditionalChange = (index, field, value) => {
    if (index == 0) {
      setNewService({
        ...newService,
        [field]: value,
      });
    } else {
      setServiceList(prev => prev.map((it, idx) => {
        if (idx == index) {
          it[field] = value;
        }
        return it;
      }));
    }
  }

  const onMultiCheck = (field, value, id) => {
    switch (field) {
      case 'category':
        setInfo(prev => {
          return {
            ...prev,
            category: value ? context.getSettingList('category').map(it => it.id) : []
          }
        });
        break;
      case 'area':
        const selectedArea = context.getSettingList('area').find(it => it.id == id);
        setInfo(prev => {
          return {
            ...prev,
            area: value ?
              (prev?.area?.filter(it => !selectedArea.children.some(t => t.id == it)) ?? []).concat(selectedArea.children.map(it => it.id)) :
              prev?.area?.filter(it => !selectedArea.children.some(t => t.id == it))
          }
        });
        break;
      case 'holiday_type':
        setInfo(prev => {
          const holidayTypes = prev?.holiday_type?.split(',') ?? [];
          return {
            ...prev,
            holiday_type: (holidayTypes.includes(value) ? holidayTypes.filter(it => it != value) : holidayTypes.concat(value)).join(',')
          };
        });
        break;
      default:
    }
  };

  const onAddSNS = () => {
    if (newSNS == '') {
      return;
    }
    setSNSList(prev => prev.concat(newSNS));
    setNewSNS('');
  }

  const onDeleteSNS = index => {
    setSNSList(prev => prev.filter((it, idx) => idx != index));
  }

  const onSNSChange = (index, value) => {
    if (index == 0) {
      setNewSNS(value);
    } else {
      setSNSList(prev => prev.map((it, idx) => {
        if (idx == index) {
          it = value;
        }
        return it;
      }));
    }
  }

  const onSave = async (e) => {
    e.preventDefault();

    if (password == '') {
      context.showAlert(strings.facility.input_password);
      return;
    }
    if ((info?.name?.length ?? 0) < 1) {
      context.showAlert('시설명을 입력하세요.');
      return;
    }
    if ((info?.price ?? 0) < 1) {
      context.showAlert('시설 단가를 입력하세요.');
      return;
    }
    if ((info?.fee ?? 0) < 1) {
      context.showAlert('시설 수수료를 입력하세요.');
      return;
    }
    if ((info?.nfc?.length ?? 0) < 1) {
      context.showAlert('NFC 주소를 입력하세요.');
      return;
    }
    if ((info?.category?.length ?? 0) < 1) {
      context.showAlert(strings.facility.select_category);
      return;
    }
    if ((info?.area?.length ?? 0) < 1) {
      context.showAlert(strings.facility.select_area);
      return;
    }
    if (images.length < 1) {
      context.showAlert(strings.facility.select_image);
      return;
    }
    if ((info?.lat ?? 0) < 1 || (info?.lng ?? 0) < 1) {
      context.showAlert('주소를 지정하세요.');
      return;
    }
    if ((info?.address_detail?.length ?? 0) < 1) {
      context.showAlert('상세주소를 입력하세요.');
      return;
    }
    // if (serviceList.length < 1) {
    //   context.showAlert(strings.facility.input_service);
    //   return;
    // }
    console.log('save faility info >>>>>>>>>', info);
    const reservation = info?.reservation ?? '';
    const reservationTime = info?.reservation_time ?? [];
    const facilityService = info?.service ?? [];

    let imageList = [];
    if (images.some(it => it.file != null)) {
      const uploadResult = await uploadImage();
      let index = 0;
      images.forEach(it => {
        if (it.file != null) {
          imageList.push(uploadResult[index++]);
        } else {
          imageList.push(it.url);
        }
      });
    } else {
      imageList = images.map(it => it.url);
    }

    context.post(
      `facility/${id}`,
      {
        ...info,
        category: info.category.join(','),
        area: info.area.join(','),
        photo: imageList.join(','),
        service: JSON.stringify(serviceList),
        items: facilityService.join(','),
        reservation: reservation,
        reservation_time: reservationTime.join(','),
        sns_url: snsList.join('|'),
        admin: {
          user_id: admin?.user_id,
          password
        }
      },
      () => {
        context.showAlert(strings.msg.saved);
        history.push('/facility');
      }
    );
  }

  const setAddressFromCoord = (lat, lng) => {
    geocoder.coord2Address(lng, lat, (result) => {
      console.log(result);
      const address = result[0].road_address
        ? result[0].road_address.address_name
        : result[0].address.address_name;

      setInfo({ ...info, address: address, lat, lng })
    })
  }

  const onSearchAddress = () => {
    postCodePopup({
      onComplete: (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname;
          }
          if (data.buildingName !== '') {
            extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
          }
          fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        console.log(fullAddress);

        getCoordFromAddress(data.address);
      }
    })
  }

  const getCoordFromAddress = (address) => {
    geocoder.addressSearch(address, (result, status) => {
      if (status == kakao.maps.services.Status.OK) {
        const lat = result[0].y;
        const lng = result[0].x;

        setInfo({ ...info, address: address, lat, lng });
        setMapCenter({ lat: lat, lng: lng });
      }
    });
  }

  return (
    <form className='form-horizontal form-bordered' onSubmit={onSave}>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.facility.main_image}</label>
        <div className='col-xs-6 form-inline'>
          {
            images.map((image, idx) => (
              <ImageSelect
                key={idx}
                image_url={image.url.getFullUrl()}
                width={'30%'}
                height={'30%'}
                onSelect={(e) => onImageChange(e, idx)}
                onDelete={() => onImageDelete(idx)} />
            ))
          }
          {
            images.length < 10 &&
            <ImageSelect
              image_url={''}
              width={'30%'}
              height={'30%'}
              onSelect={(e) => onImageChange(e, 0)} />
          }
        </div>
      </div>
      {/*<div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.facility.member_name}</label>
        <div className="col-xs-6">
          <select className='form-control' value={info?.member || 0}
                  onChange={e => setInfo({ ...info, member: e.target.value })}>
            <option value={0} />
            {
              memberList.map((it, idx) => (
                <option key={idx} value={it.id}>{it.name}</option>
              ))
            }
          </select>
        </div>
      </div>*/}
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.facility.admin}</label>
        <div className="col-xs-6">
          <input type='text' autoComplete='off' className='form-control'
            value={admin?.user_id || ''} required
            onChange={e => setAdmin({ ...admin, user_id: e.target.value })} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'비밀번호'}</label>
        <div className="col-xs-6">
          <input type='text' autoComplete='off' className='form-control'
            value={password}
            onChange={e => setPassword(e.target.value)} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.facility.name}</label>
        <div className="col-xs-6">
          <input type='text' autoComplete='off' className='form-control'
            value={info?.name || ''} required
            onChange={e => setInfo({ ...info, name: e.target.value })} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.facility.contact}</label>
        <div className="col-xs-6">
          <input type='tel' autoComplete='off' className='form-control'
            value={info?.phone || ''} required
            onChange={e => setInfo({ ...info, phone: e.target.value })} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.facility.address}</label>
        <div className="col-xs-6">
          <input type='text' autoComplete='off' className='form-control'
            value={info?.address || ''} required disabled
            onChange={e => setInfo({ ...info, address: e.target.value })} />
        </div>
        <div className='col-xs-2'>
          <button type='button' className='form-control' onClick={() => onSearchAddress()}>
            검색
          </button>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'상세주소'}</label>
        <div className="col-xs-6">
          <input type='text' autoComplete='off' className='form-control'
            value={info?.address_detail || ''} required
            onChange={e => setInfo({ ...info, address_detail: e.target.value })} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.facility.rating}</label>
        <div className="col-xs-6">
          <select className='form-control' required value={info?.level}
            onChange={e => setInfo({ ...info, level: e.target.value })}>
            <option value='' />
            {
              context.getSettingList('level').map((level, idx) => (
                <option key={idx} value={level.id}>{level.name}</option>
              ))
            }
          </select>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'시설 단가'}</label>
        <div className="col-xs-6">
          <input type='number' autoComplete='off' className='form-control'
            value={info?.price || ''} required
            onChange={e => setInfo({ ...info, price: e.target.value })} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'시설 수수료'}</label>
        <div className="col-xs-6">
          <input type='number' autoComplete='off' className='form-control'
            value={info?.fee || ''} required
            onChange={e => setInfo({ ...info, fee: e.target.value })} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'NFC 주소'}</label>
        <div className="col-xs-6">
          <input type='text' autoComplete='off' className='form-control'
            value={info?.nfc || ''} required
            onChange={e => setInfo({ ...info, nfc: e.target.value })} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'예약문구'}</label>
        <div className="col-xs-6">
          <input type='text' autoComplete='off' className='form-control'
            value={info?.reservation || ''}
            onChange={e => setInfo({ ...info, reservation: e.target.value })} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'예약금액'}</label>
        <div className="col-xs-6">
          <input type='number' autoComplete='off' className='form-control'
            value={info?.reservation_price || ''}
            onChange={e => setInfo({ ...info, reservation_price: e.target.value })} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'예약할인'}</label>
        <div className="col-xs-6">
          <input type='number' autoComplete='off' className='form-control'
            value={info?.reservation_discount || ''}
            onChange={e => setInfo({ ...info, reservation_discount: e.target.value })} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'예약시간 설정'}</label>
        <div className="col-xs-6">
          <div className="d-flex flex-column">
            <label className='col-form-label'>{'오전'}</label>
            <div className="d-flex flex-row">
              {
                ['06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30'].map((item, idx) => (
                  <div key={idx} className={"btn btn-outline-" + (info?.reservation_time?.includes(item) ? 'info' : 'dark')}
                    style={{ marginRight: 5 }}
                    onClick={() => {
                      setInfo(prev => {
                        const timeList = prev?.reservation_time ?? [];
                        return {
                          ...prev,
                          reservation_time: timeList.includes(item) ?
                            timeList.filter(it => it != item) :
                            timeList.concat(item)
                        }
                      });
                    }}>{item}</div>
                ))
              }
            </div>
            <label className='col-form-label'>{'오후'}</label>
            <div className="d-flex flex-row">
              {
                ['12:00', '12:30', '13:00', '13:30', '14:00', '15:30', '16:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'].map((item, idx) => (
                  <div key={idx} className={"btn btn-outline-" + (info?.reservation_time?.includes(item) ? 'info' : 'dark')}
                    style={{ marginRight: 5 }}
                    onClick={() => {
                      setInfo(prev => {
                        const timeList = prev?.reservation_time ?? [];
                        return {
                          ...prev,
                          reservation_time: timeList.includes(item) ?
                            timeList.filter(it => it != item) :
                            timeList.concat(item)
                        }
                      });
                    }}>{item}</div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.facility.category}</label>
        <div className="col-xs-6">
          <label className='control-label m-b-5 form-inline justify-content-start'>
            <input type='checkbox' name='category'
              checked={info?.category?.length == context.getSettingList('category').length}
              onChange={e => onMultiCheck('category', e.target.checked)}
            />&nbsp;{strings.common.select_all}
          </label>
          <div className='form-inline'>
            {
              context.getSettingList('category').map((cate, idx) => (
                <label key={idx} className='control-label m-r-20'>
                  <input
                    type='checkbox' name='category' checked={info?.category?.includes(cate.id)}
                    value={cate.id}
                    onChange={() => {
                      setInfo(prev => {
                        const categoryList = prev?.category ?? [];
                        return {
                          ...prev,
                          category: categoryList.includes(cate.id) ?
                            categoryList.filter(it => it != cate.id) :
                            categoryList.concat(cate.id)
                        }
                      });
                    }} />&nbsp;{cate.name}
                </label>
              ))
            }
          </div>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.common.area}</label>
        <div className="col-xs-6">
          <table className='table table-bordered mb-0'>
            <tbody>
              {
                context.getSettingList('area').map((parent, idx) => (
                  <Fragment key={idx}>
                    <tr>
                      <td rowSpan={2}>{parent.name}</td>
                      <td>
                        <label className='control-label form-inline mb-0'>
                          <input type='checkbox' name='category'
                            checked={parent.children.every(it => info?.area?.includes(it.id))}
                            onChange={e => onMultiCheck('area', e.target.checked, parent.id)}
                          />&nbsp;{strings.common.select_all}
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-left'>
                        {
                          parent.children.map((child, childIdx) => (
                            <label key={childIdx} className='control-label mb-0 m-r-10'>
                              <input
                                type='checkbox' name='category' checked={info?.area?.includes(child.id)}
                                onChange={() => {
                                  setInfo(prev => {
                                    const areaList = prev?.area ?? [];
                                    return {
                                      ...prev,
                                      area: areaList.includes(child.id) ?
                                        areaList.filter(it => it != child.id) :
                                        areaList.concat(child.id)
                                    }
                                  });
                                }} />&nbsp;{child.name}
                            </label>
                          ))
                        }
                      </td>
                    </tr>
                  </Fragment>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.facility.work_hour_holiday}</label>
        <div className="col-xs-6">
          <div className='form-inline m-b-10'>
            {
              Object.keys(strings.facility.holidays).map(value => (
                <label className='control-label m-r-10' key={value}>
                  <input type='checkbox' value={value} checked={info?.holiday_type?.includes(value)}
                    onChange={e => onMultiCheck('holiday_type', e.target.value)}
                  />&nbsp;{strings.facility.holidays[value]}
                </label>
              ))
            }
            <DateTime
              dateFormat={FORMAT.DATE}
              timeFormat={false}
              inputProps={{ readOnly: !info?.holiday_type?.includes('S') }}
              value={info?.holiday ?? ''}
              closeOnSelect={true}
              className="ml-xs-2"
              onChange={(e) => {
                if (!info?.holiday_type?.includes('S')) {
                  return;
                }
                if (typeof e === 'string') {
                  setInfo(prev => {
                    return {
                      ...prev,
                      holiday: e
                    }
                  });
                } else {
                  // setFrom(e.format(FORMAT.DATE));
                  setInfo(prev => {
                    return {
                      ...prev,
                      holiday: e.format(FORMAT.DATE)
                    }
                  });
                }
              }} />
          </div>
          <div className='form-inline m-b-10 m-t-10'>
            <label className='col-xs-4 control-label'>{strings.facility.work_hour}</label>
            <input type='time' className='form-control m-l-5' value={info?.work_hour_from || ''}
              placeholder={'09:00'} required
              onChange={e => setInfo({ ...info, work_hour_from: e.target.value })} />
            <label className='control-label m-l-5'>{'-'}</label>
            <input type='time' className='form-control m-l-5' value={info?.work_hour_to || ''}
              placeholder={'18:00'} required
              onChange={e => setInfo({ ...info, work_hour_to: e.target.value })} />
          </div>
          <div className='form-inline'>
            <label className='col-xs-4 control-label'>{strings.facility.weekend_work_hour}</label>
            <input type='time' className='form-control m-l-5' value={info?.weekend_hour_from || ''}
              placeholder={'09:00'}
              required={info?.holiday_type?.includes('W')}
              readOnly={!info?.holiday_type?.includes('W')}
              onChange={e => setInfo({ ...info, weekend_hour_from: e.target.value })} />
            <label className='control-label m-l-5'>{'-'}</label>
            <input type='time' className='form-control m-l-5' value={info?.weekend_hour_to || ''}
              placeholder={'18:00'}
              required={info?.holiday_type?.includes('W')}
              readOnly={!info?.holiday_type?.includes('W')}
              onChange={e => setInfo({ ...info, weekend_hour_to: e.target.value })} />
          </div>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'이용안내'}</label>
        <div className="col-xs-6">
          <ReactQuill
            theme='snow'
            className=''
            modules={editorModules}
            formats={editorFormats}
            value={info?.guide || ''}
            onChange={content => {
              setInfo(prev => {
                return {
                  ...prev,
                  guide: content
                }
              });
            }} />
          {/*<textarea
            className='form-control'
            rows={20}
            required
            value={info?.guide || ''}
            onChange={e => setInfo({ ...info, guide: e.target.value })} />*/}
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'편의시설'}</label>
        <div className="col-xs-6">
          <div className='form-inline'>
            {
              FACILITY_SERVICES.map((service, idx) => (
                <label key={idx} className='control-label m-r-20'>
                  <input
                    type='checkbox' name='service' checked={info?.service?.includes(service.id)}
                    value={service.id}
                    onChange={() => {
                      setInfo(prev => {
                        const services = prev?.service ?? [];
                        return {
                          ...prev,
                          service: services.includes(service.id) ?
                            services.filter(it => it != service.id) :
                            services.concat(service.id)
                        }
                      });
                    }} />&nbsp;{service.name}
                </label>
              ))
            }
          </div>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'SNS 주소'}</label>
        <div className="col-xs-6">
          {
            snsList.map((it, idx) => (
              <div key={idx} className='form-inline m-b-10'>
                <input type='text' autoComplete='off' className='form-control' value={it}
                  onChange={e => onSNSChange(idx, e.target.value)} />
                <button type='button' className='btn btn-icon' onClick={() => onDeleteSNS(idx)}>
                  <i className='fa fa-times' />
                </button>
              </div>
            ))
          }
          <div className='form-inline'>
            <input type='text' autoComplete='off' className='form-control' value={newSNS}
              onChange={e => onSNSChange(0, e.target.value)} />
            <button type='button' className='btn btn-icon' onClick={onAddSNS}>
              <i className='fa fa-plus' />
            </button>
          </div>
          {/* <input type='text' autoComplete='off' className='form-control'
            value={info?.sns_url || ''}
            onChange={e => setInfo({ ...info, sns_url: e.target.value })} /> */}
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.facility.additional}</label>
        <div className="col-xs-6">
          {
            serviceList.map((it, idx) => (
              <div key={idx} className='form-inline m-b-10'>
                <label className='control-label'>{strings.common.name}</label>
                <input type='text' className='form-control m-l-5' value={it.name}
                  onChange={e => onAdditionalChange(idx, 'name', e.target.value)} />
                <label className='control-label m-l-15'>{strings.category.price}</label>
                <input type='number' className='form-control m-l-5' value={it.price}
                  onChange={e => onAdditionalChange(idx, 'price', e.target.value)} />
                <button type='button' className='btn btn-icon' onClick={() => onDeleteAdditional(idx)}>
                  <i className='fa fa-times' />
                </button>
              </div>
            ))
          }
          <div className='form-inline'>
            <label className='control-label'>{strings.common.name}</label>
            <input type='text' className='form-control m-l-5' value={newService.name}
              onChange={e => onAdditionalChange(0, 'name', e.target.value)} />
            <label className='control-label m-l-15'>{strings.category.price}</label>
            <input type='number' className='form-control m-l-5' value={newService.price}
              onChange={e => onAdditionalChange(0, 'price', e.target.value)} />
            <button type='button' className='btn btn-icon' onClick={onAddAdditional}>
              <i className='fa fa-plus' />
            </button>
          </div>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'서비스 설명'}</label>
        <div className="col-xs-6">
          <ReactQuill
            theme='snow'
            className=''
            modules={editorModules}
            value={info?.service_info || ''}
            onChange={content => {
              setInfo(prev => {
                return {
                  ...prev,
                  service_info: content
                }
              });
            }} />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{'지도'}</label>
        <div className="col-xs-6">
          {/* <div className='form-inline m-b-5'>
            <label className='control-label'>
              <input type='radio' checked={(info?.map_type ?? 'M') == 'M'}
                     onChange={() => setInfo({ ...info, map_type: 'M' })} />
              &nbsp;{strings.facility.map}
            </label>
            <label className='control-label m-l-20'>
              <input type='radio' checked={(info?.map_type ?? '') == 'G'}
                     onChange={() => setInfo({ ...info, map_type: 'G' })} />
              &nbsp;{strings.facility.google_map}
            </label>
          </div>
          {
            (info?.map_type ?? 'M') == 'M' ?
              <div>
                <div className='form-inline m-b-5'>
                  <label className='col-xs-2 control-label'>{strings.facility.client_id}</label>
                  <input type='text' className='form-control flex-fill' value={info?.client_id || ''}
                         onChange={e => setInfo({ ...info, client_id: e.target.value })} />
                </div>
                <div className='form-inline'>
                  <label className='col-xs-2 control-label'>{strings.facility.client_secret}</label>
                  <input type='text' className='form-control flex-fill' value={info?.client_secret || ''}
                         onChange={e => setInfo({ ...info, client_secret: e.target.value })} />
                </div>
              </div>
              :
              <div className='form-inline'>
                <label className='col-xs-2 control-label'>{strings.facility.api_key}</label>
                <input type='text' className='form-control flex-fill' value={info?.api_key || ''}
                       onChange={e => setInfo({ ...info, api_key: e.target.value })} />
              </div>
          } */}
          <Map
            center={mapCenter}
            style={{ width: "100%", height: "360px" }}
            onClick={(_t, mouseEvent) => {
              setAddressFromCoord(mouseEvent.latLng.getLat(), mouseEvent.latLng.getLng());
            }}
          >
            <MapMarker // 마커를 생성합니다
              position={{
                // 마커가 표시될 위치입니다
                lat: info?.lat ?? 37.570749,
                lng: info?.lng ?? 127.068233,
              }}
            />
          </Map>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-xs-2 col-form-label'>{strings.facility.recommend}</label>
        <div className="col-xs-6">
          <label className='control-label m-b-5 form-inline justify-content-start'>
            <input type='checkbox' name='recommend' checked={(info?.recommend ?? 0) == 1}
              onChange={e => setInfo({ ...info, recommend: 1 - (info?.recommend ?? 0) })} />
          </label>
        </div>
      </div>
      <div className='form-group row m-t-20'>
        <button type='button' className='btn btn-default col-xs-2' onClick={() => history.push('/facility')}>
          {strings.common.back}
        </button>
        <button type='submit' className='btn btn-primary col-xs-2 m-l-15'>
          {strings.common.save}
        </button>
      </div>
    </form>
  );

}

export default withRouter(FacilityInfoFragment);
