import React from 'react';
import { withRouter } from "react-router-dom";
import axios from "axios";
import { rootStore } from "./mobx/store";
import { inject, observer } from "mobx-react";
import { ReactNotifications, Store } from 'react-notifications-component';
import Lightbox from 'react-image-lightbox';

import { PageSettings } from './config/page-settings.js';
import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import Content from './components/content/content.jsx';
import LoadingBar from "./components/control/loading-bar";
import CommonModal from "./components/control/modal";
import strings from "./lang/strings";
import { API_URL, HEADER, RESULT_CODE } from "./config/const";
import MemberDetailPopup from './pages/facility/popup/member-detail-popup.js';


class App extends React.Component {
  constructor(props) {
    super(props);

    this.visitAudioRef = React.createRef(null);

    this.toggleMobileSidebar = () => {
      this.setState(state => ({
        pageSidebarToggled: !state.pageSidebarToggled
      }));
    }
    this.handleSetPageSidebar = (value) => {
      this.setState({
        pageSidebar: value
      });
    }
    this.handleSetPageHeader = (value) => {
      this.setState({
        pageHeader: value
      });
    };
    this.handleSetPageBoxedLayout = (value) => {
      if (value === true) {
        document.body.classList.add('boxed-layout');
      } else {
        document.body.classList.remove('boxed-layout');
      }
    }
    this.toggleLoadingBar = isLoading => {
      this.setState({ pageLoading: isLoading });
    }
    this.addNotification = (title, message, type = 'success') => {
      Store.addNotification({
        title,
        message,
        type,
        insert: 'top',
        container: 'top-right',
        /*animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],*/
        dismiss: {
          duration: 5000,
          // onScreen: true
        }
      });
    }
    this.showGallery = (image_list, index = 0) => {
      this.setState({
        galleryShow: true,
        galleryData: image_list,
        galleryIndex: index,
      });
    }
    this.showModal = (title, text, okBtn, cancelBtn, cb) => {
      this.setState({
        modal: {
          show: true,
          title: title,
          text: text,
          subText: '',
          okBtn: okBtn,
          cancelBtn: cancelBtn,
          cb: cb
        }
      });
    }
    this.showAlert = (text, cb, okBtn = strings.modal.confirm) => {
      this.showModal(strings.modal.alert, text, okBtn, '', cb);
    }
    this.showConfirm = (text, cb, okBtn = strings.modal.ok, cancelBtn = strings.modal.no) => {
      this.showModal(strings.modal.confirm, text, okBtn, cancelBtn, cb);
    }
    this.showUserPopup = (info, callback) => {
      this.setState(state => ({
        member_detail: {
          ...state.member_detail,
          isOpen: true,
          info: info,
          callback: callback
        }
      }), () => {
        this.visitAudioRef.current.play()
        this.visitAudioRef.current.muted = false
      });
    }
    this.hideUserPopup = () => {
      this.setState({
        member_detail: {
          isOpen: false,
          info: {}
        }
      });
      this.visitAudioRef.current.pause()
    }
    this.playVisitAudio = () => {
      // 제휴시설관리자인 경우 방문자 발생시 오디오 재생을 위해 추가된 함수
      // 브라우저들에서 오디오 자동재생을 제한하고 있기때문에 초기 로그인시 mute상태로 한번 오디오를 재생해줘야 그 다음부터 play, pause가 정상동작함
      // 단, 로그인 된 상태에서 브라우저를 refresh하는 경우 오류가 발생함
      this.visitAudioRef.current.muted = true
      this.visitAudioRef.current.play()
    }
    this.getSettingList = (...name) => {
      let item = this.props.rootStore.getSetting;
      for (props of name) {
        item = item[props];
      }
      return item;
    }
    this.getSettingName = (code, ...name) => {
      let item = this.props.rootStore.getSetting;

      if (name == 'auth') {
        return item[name]?.find(it => it.code == code)?.name ?? '';
      }

      for (props of name) {
        item = item[props];
      }
      return Array.isArray(item) ? (item?.find(it => it.code == code)?.name ?? '') : item[code];
    }
    this.api = axios.create({
      baseURL: API_URL + '/admin/',
      headers: {
        [HEADER.LANG]: 'ko'
      }
    })
    this.post = async (uri, params, cb, showProgress = true) => {
      await this.request('POST', uri, params, cb, showProgress);
    }
    this.get = async (uri, params, cb, showProgress = true) => {
      await this.request('GET', uri, params, cb, showProgress);
    }
    this.delete = async (uri, params, cb, showProgress = true) => {
      await this.request('DELETE', uri, params, cb, showProgress);
    }
    this.request = async (method, uri, params, cb, showProgress = true) => {
      if (showProgress) {
        this.toggleLoadingBar(true);
      }

      this.api.defaults.headers.common[HEADER.AUTH_TOKEN] = this.props.rootStore.token;

      try {
        let { data } = method !== 'GET' ?
          await this.api[method.toLowerCase()](uri, params)
          :
          await this.api.get(uri, { params });

        if (showProgress) {
          this.toggleLoadingBar(false);
        }

        if (Number(data.result_code) === RESULT_CODE.SUCCESS) {
          cb?.(data.result_data);
        } else {
          this.handleApiError(data);
        }
      } catch (err) {
        console.log(err);

        if (showProgress) {
          this.toggleLoadingBar(false);
        }

        this.showAlert(err.message);
      }
    }

    this.download = async (method, uri, params, cb, showProgress = true) => {
      if (showProgress) {
        this.toggleLoadingBar(true);
      }

      this.api.defaults.headers.common[HEADER.AUTH_TOKEN] = this.props.rootStore.token;
      this.api.defaults.responseType = 'blob';


      try {
        let response = method !== 'GET' ?
          await this.api[method.toLowerCase()](uri, params)
          :
          await this.api.get(uri, { params });

        if (showProgress) {
          this.toggleLoadingBar(false);
        }


        const filename = decodeURIComponent(response.headers["x-suggested-filename"]);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

      } catch (err) {
        console.log(err);

        if (showProgress) {
          this.toggleLoadingBar(false);
        }

        this.showAlert(err.message);
      }
    }

    this.downloadExcel = (fileName, data) => {
      let csv = '';
      for (let i = 0; i < data.length; i++) {
        let row = data[i];
        for (let j = 0; j < row.length; j++) {
          let val = row[j] === null ? '' : row[j].toString();
          val = val.replace(/\t/gi, " ");
          if (j > 0)
            csv += '\t';
          csv += val;
        }
        csv += '\n';
      }

      // for UTF-16
      let cCode, bArr = [];
      bArr.push(255, 254);
      for (let i = 0; i < csv.length; ++i) {
        cCode = csv.charCodeAt(i);
        bArr.push(cCode & 0xff);
        bArr.push(cCode / 256 >>> 0);
      }

      let blob = new Blob([new Uint8Array(bArr)], { type: 'text/csv;charset=UTF-16LE;' });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        let link = document.createElement("a");
        if (link.download !== undefined) {
          let url = window.URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      }
    }

    this.handleApiError = e => {
      if (Number(e.result_code) === RESULT_CODE.EMPTY_TOKEN ||
        Number(e.result_code) === RESULT_CODE.TOKEN_EXPIRED) {
        this.props.history.replace('/login');
        return;
      }

      this.showAlert(e.result_msg);
    }

    this.state = {
      hasScroll: false,

      pageHeader: true,
      handleSetPageHeader: this.handleSetPageHeader,

      pageSidebar: true,
      pageSidebarToggled: false,
      handleSetPageSidebar: this.handleSetPageSidebar,
      toggleMobileSidebar: this.toggleMobileSidebar,

      handleSetPageBoxedLayout: this.handleSetPageBoxedLayout,

      pageLoading: false,
      toggleLoadingBar: this.toggleLoadingBar,

      modal: {
        show: false,
        title: '',
        text: '',
        subText: '',
        okBtn: '',
        cancelBtn: '',
        cb: null
      },
      showModal: this.showModal,
      showAlert: this.showAlert,
      showConfirm: this.showConfirm,

      addNotification: this.addNotification,

      showGallery: this.showGallery,
      galleryShow: false,
      galleryData: [],
      galleryIndex: 0,

      post: this.post,
      get: this.get,
      delete: this.delete,
      download: this.download,

      getSettingInfo: this.getSettingInfo,
      getSettingName: this.getSettingName,
      getSettingList: this.getSettingList,

      downloadExcel: this.downloadExcel,

      member_detail: {
        isOpen: false,
        info: {},
        callback: null
      },
      showUserPopup: this.showUserPopup,
      playVisitAudio: this.playVisitAudio
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    this.getSettingInfo().then();
    this.timer = setInterval(() => {
      if (this.props.rootStore.getMe != null && (this.props.rootStore.getMe?.facility ?? 0) != 0) {
        this.checkVisitMember();
      }
    }, 5000);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  getSettingInfo = async () => {
    await this.get(
      'dashboard/getAppData',
      {},
      response => {
        this.props.rootStore.saveSetting(response);
      },
      false
    );
  }

  checkVisitMember = async () => {
    if (this.state.member_detail.isOpen) return

    await this.get(
      'facility/visitor_info',
      {
        facility: this.props.rootStore.getMe?.facility
      },
      response => {
        if (response) {
          if (response.user_level == null || response.user_level == '') { // 멤버쉽 구매회원 여부 체크
            this.showAlert('정회원이 아닙니다.');
            this.confirmVisitMember(response.id);
          } else if (response.user_level > response.facility_level) { // 멤버쉽 등급이 방문시설의 등급보다 낮은 경우
            this.showAlert('시설등급보다 낮은 등급의 멤버쉽 이용회원입니다.');
            this.confirmVisitMember(response.id);
          } else {
            this.showUserPopup(response);
          }
        }
      },
      false
    )
  }

  confirmVisitMember = async (id) => {
    await this.post(
      `facility/visitor_confirm/${id}`,
      {},
      () => {
      }
    );
  }

  handleScroll = () => {
    this.setState({
      hasScroll: window.scrollY > 0
    });
    let elm = document.getElementsByClassName('nvtooltip');
    for (let i = 0; i < elm.length; i++) {
      elm[i].classList.add('d-none');
    }
  }

  onModalClose = res => {
    this.setState(state => ({
      modal: { ...state.modal, show: false }
    }), () => this.state.modal.cb?.(res));
  }

  render() {
    return (
      <PageSettings.Provider value={this.state}>
        <div className={
          'fade page-sidebar-fixed show page-container page-header-fixed ' +
          (this.state.pageSidebar ? '' : 'page-without-sidebar ') +
          (this.state.pageSidebarToggled ? 'page-sidebar-toggled ' : '') +
          (this.state.hasScroll ? 'has-scroll ' : '')
        }>
          <ReactNotifications />
          {this.state.pageHeader && <Header />}
          {this.state.pageSidebar && <Sidebar />}
          <Content />
          <CommonModal {...this.state.modal} onModalClose={this.onModalClose} />
          {this.state.galleryShow &&
          <Lightbox
            reactModalStyle={{ overlay: { zIndex: 10000 } }}
            mainSrc={this.state.galleryData[this.state.galleryIndex]}
            nextSrc={this.state.galleryData[(this.state.galleryIndex + 1) % this.state.galleryData.length]}
            prevSrc={this.state.galleryData[(this.state.galleryIndex + this.state.galleryData.length - 1) % this.state.galleryData.length]}
            onCloseRequest={() => this.setState({
              galleryShow: false,
              galleryData: [],
              galleryIndex: 0
            })}
            onMovePrevRequest={() =>
              this.setState({
                galleryIndex: (this.state.galleryIndex + this.state.galleryData.length - 1) % this.state.galleryData.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                galleryIndex: (this.state.galleryIndex + 1) % this.state.galleryData.length,
              })
            }
          />
          }
          {this.state.pageLoading && <LoadingBar />}
          <MemberDetailPopup
            {...this.state.member_detail}
            onClose={() => this.hideUserPopup()}
          />
          {/* 제휴시설관리자인 경우 방문자 발생시 재생되는 오디오 */}
          <audio ref={this.visitAudioRef}>
            <source src='./assets/visit.wav' type='audio/wav' />
          </audio>
        </div>
      </PageSettings.Provider>
    )
  }
}

export default withRouter(inject('rootStore')(observer(App)));
