import React, { useRef } from "react";

const ImageSelect = React.memo(({ image_url, width, height, onSelect, onDelete }) => {
  const imageRef = useRef();

  return (
    <div style={{
      display: 'block', position: 'relative',
      paddingTop: height ? height : '100%',
      width: width ? width : '100%'
    }}>
      <button className="btn btn-white"
              type='button'
              style={{
                backgroundImage: `url(${image_url})`,
                backgroundSize: "cover", backgroundPosition: "center",
                position: "absolute", top: "1px", left: "1px", right: "1px", bottom: "1px", width: "100%"
              }}
              onClick={() => imageRef?.current.click()}>
        {
          !image_url &&
          <i className="fa fa-plus"/>
        }
        <input type='file' accept='image/*' className='hide'
               onChange={onSelect}
               ref={imageRef}/>
      </button>
      {
        image_url &&
        <button className="btn btn-danger btn-icon btn-sm"
                type='button'
                style={{ position: "absolute", top: "2px", right: "0px" }}
                onClick={onDelete}>
          <i className="fa fa-times"/>
        </button>
      }
    </div>
  );
});

export default ImageSelect;
