import Container from "../../components/control/container";
import strings from "../../lang/strings";
import { useContext, useEffect, useState } from "react";
import { PageSettings } from "../../config/page-settings";
import { withRouter } from "react-router-dom";

const AffiliateDetail = ({ id, history }) => {

  const context = useContext(PageSettings);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = () => {
    if (id < 1) {
      return;
    }

    context.get(
      `facility/affiliate/detail/${id}`,
      {},
      response => setInfo(response)
    );
  }

  return (
    <Container title={strings.menu.affiliate}>
      <div className='form-horizontal form-bordered'>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.facility.member_name}</label>
          <div className='col-xs-6'>
            {info?.name}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{'연락처'}</label>
          <div className='col-xs-6'>
            {info?.phone}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.facility.category}</label>
          <div className='col-xs-6'>
            {context.getSettingList('category').find(it => it.id == info?.category)?.name || '-'}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.facility.address}</label>
          <div className='col-xs-6'>
            {context.getSettingList('area').find(it => it.id == info?.area)?.name || '-'}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.affiliate_request.memo}</label>
          <div className='col-xs-6'>
            {info?.memo}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{'문의경로'}</label>
          <div className='col-xs-6'>
            {info?.route}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{'문의날짜'}</label>
          <div className='col-xs-6'>
            {info?.create_date}
          </div>
        </div>
      </div>
      <div className='form-group row m-t-20'>
        <button type='button' className='btn btn-default col-xs-2 m-r-10' onClick={() => history.goBack()}>
          {strings.common.back}
        </button>
        <button type='button' className='btn btn-primary col-xs-2' onClick={() => history.push(`/facility/affiliate/${id}`)}>
          {strings.facility.add}
        </button>
      </div>
    </Container>
  );

}

export default withRouter(AffiliateDetail);
