import Container from "../../components/control/container";
import strings from "../../lang/strings";
import { PageSettings } from "../../config/page-settings";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import $ from "jquery";
import { useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import AddTicketPopup from "./popup/add-ticket-popup";

const Member = ({ history }) => {

  const context = useContext(PageSettings);

  const ageList = context.getSettingList('age');
  const levelList = context.getSettingList('level');
  const categoryList = context.getSettingList('category');

  const [gender, setGender] = useState(Object.keys(strings.push_history.gender));
  const [age, setAge] = useState(ageList);
  const [rating, setRating] = useState(levelList.map(it => it.id));
  const [category, setCategory] = useState(categoryList.map(it => it.id));
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const [popup, setPopup] = useState({
    isOpen: false,
    id: 0
  });
  const showPopup = id => setPopup({
    isOpen: true,
    id
  });
  const hidePopup = () => setPopup({
    isOpen: false,
    id: 0
  });

  const genderRef = useRef('');
  const ageRef = useRef('');
  const ratingRef = useRef('');
  const categoryRef = useRef('');
  const fromRef = useRef('');
  const toRef = useRef('');

  useEffect(() => {
    genderRef.current = gender.join(',');
  }, [gender]);
  useEffect(() => {
    ageRef.current = age.join(',');
  }, [age]);
  useEffect(() => {
    ratingRef.current = rating.join(',');
  }, [rating]);
  useEffect(() => {
    categoryRef.current = category.join(',');
  }, [category]);
  useEffect(() => {
    fromRef.current = from;
  }, [from]);
  useEffect(() => {
    toRef.current = to;
  }, [to]);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => tableRef.current?.draw(refresh);

  const searchOptions = [
    { value: 'email', name: strings.facility.id },
    { value: 'name', name: strings.common.name },
    { value: 'phone', name: strings.common.phone },
  ];
  
  const searchType = [
    { value: 0, name: '전체' },
    { value: 1, name: '기업회원' },
    { value: 2, name: '일반회원' },
  ];

  const onDetail = id => history.push(`/member/${id}`);

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        `member/${id}`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };
  
  const onAddTicket = id => {
    showPopup(id);
  };

  const onMultiCheck = (field, value) => {
    switch (field) {
      case 'age':
        setAge(value ? ageList : []);
        break;
      case 'level':
        setRating(value ? levelList.map(it => it.id) : []);
        break;
      case 'category':
        setCategory(value ? categoryList.map(it => it.id) : []);
        break;
      default:
    }
  };

  return (
    <Container title={strings.menu.member}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-group row form-inline'>
            <label className='col-md-1 col-form-label'>{strings.common.gender}</label>
            <div className='col-md-10'>
              <div className='form-inline'>
                {
                  Object.keys({
                    'M': '남성',
                    'F': '여성'
                  }).map((sex, idx) => (
                    <label key={idx} className='control-label m-r-20'>
                      <input
                        type='checkbox' name='gender' checked={gender.includes(sex)}
                        onChange={() => {
                          setGender(prev => prev.includes(sex) ? prev.filter(it => it != sex) : [...prev, sex]);
                        }} />&nbsp;{strings.push_history.gender[sex]}
                    </label>
                  ))
                }
              </div>
            </div>
          </div>
          <div className='form-group row form-inline'>
            <label className='col-md-1 col-form-label'>{strings.common.age}</label>
            <div className="col-md-10">
              <label className='control-label form-inline justify-content-start m-b-5'>
                <input type='checkbox' name='age'
                       checked={age.length == ageList.length}
                       onChange={(e) => onMultiCheck('age', e.target.checked)}
                />&nbsp;{strings.common.select_all}
              </label>
              <div className='form-inline'>
                {
                  ageList.map((it, idx) => (
                    <label key={idx} className='control-label m-r-20'>
                      <input
                        type='checkbox' name='age' checked={age.includes(it)} value={it}
                        onChange={() => {
                          setAge(prev => prev.includes(it) ? prev.filter(p => p != it) : [...prev, it]);
                        }} />&nbsp;{strings.formatString(strings.push_history.age, it)}
                    </label>
                  ))
                }
              </div>
            </div>
          </div>
          <div className='form-group row form-inline'>
            <label className='col-md-1 col-form-label'>{strings.facility.rating}</label>
            <div className="col-md-10">
              <label className='control-label m-b-5 form-inline justify-content-start'>
                <input type='checkbox' name='level'
                       checked={rating.length == levelList.length}
                       onChange={(e) => onMultiCheck('level', e.target.checked)}
                />&nbsp;{strings.common.select_all}
              </label>
              <div className='form-inline'>
                {
                  levelList.map((level, idx) => (
                    <label key={idx} className='control-label m-r-20'>
                      <input
                        type='checkbox' name='level' checked={rating.includes(level.id)} value={level.id}
                        onChange={() => {
                          setRating(prev => prev.includes(level.id) ? prev.filter(it => it != level.id) : [...prev, level.id]);
                        }} />&nbsp;{level.name}
                    </label>
                  ))
                }
              </div>
            </div>
          </div>
          <div className='form-group row form-inline'>
            <label className='col-md-1 col-form-label'>{strings.member.interest_category}</label>
            <div className="col-md-10">
              <label className='control-label m-b-5 form-inline justify-content-start'>
                <input type='checkbox' name='category'
                       checked={category.length == categoryList.length}
                       onChange={(e) => onMultiCheck('category', e.target.checked)}
                />&nbsp;{strings.common.select_all}
              </label>
              <div className='form-inline'>
                {
                  categoryList.map((cate, idx) => (
                    <label key={idx} className='control-label m-r-20'>
                      <input
                        type='checkbox' name='category' checked={category.includes(cate.id)} value={cate.id}
                        onChange={() => {
                          setCategory(prev => prev.includes(cate.id) ? prev.filter(it => it != cate.id) : [...prev, cate.id]);
                        }} />&nbsp;{cate.name}
                    </label>
                  ))
                }
              </div>
            </div>
          </div>
          <div className='form-group row form-inline'>
            <label className='col-md-1 col-form-label'>{strings.common.keyword}</label>
            <div className='col-md-10'>
              <div className='form-inline'>
                <SearchSelect
                  id='searchColumn'
                  value={searchOptions[0].value}
                  data={searchOptions}
                  onChange={() => refreshTable(true)} />
                <SearchInput
                  id='searchInput'
                  action={() => refreshTable(true)} />
              </div>
            </div>
          </div>
          <div className='form-group row form-inline'>
            <label className='col-md-1 col-form-label'>{'회원유형'}</label>
            <div className='col-md-10'>
              <div className='form-inline'>
                <SearchSelect
                  id='searchType'
                  value={searchType[0].value}
                  data={searchType}
                  onChange={() => refreshTable(true)} />
              </div>
            </div>
          </div>
          <div className='form-horizontal row form-inline'>
            <label className='col-md-1 col-form-label'>{strings.push_history.remain_ticket}</label>
            <div className='col-md-10'>
              <div className='form-inline'>
                <input type='text' autoComplete='off' className='form-control'
                       value={from}
                       onChange={e => setFrom(e.target.value)} />
                <label className='col-form-label'>&nbsp;~&nbsp;</label>
                <input type='text' autoComplete='off' className='form-control'
                       value={to}
                       onChange={e => setTo(e.target.value)} />
                <button type='button' className='btn btn-success btn-sm ml-md-5' onClick={() => refreshTable(true)}>
                  {strings.common.search}
                </button>
                {/*<div className='ml-md-1'>
                  <button type='button' className='btn btn-info btn-sm' onClick={onDownload}>
                    {strings.common.download}
                  </button>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
        <hr className='mt-3' />
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'email', orderable: true,
              }, {
                targets: [columnNo++], data: 'name', orderable: true,
              }, {
                targets: [columnNo++], data: 'phone', orderable: true,
              }, {
                targets: [columnNo++], data: 'gender', orderable: true,
                render: value => strings.push_history.gender[value]
              }, {
                targets: [columnNo++], data: 'age', orderable: true,
                render: value => strings.formatString(strings.push_history.age, value)
              }, {
                targets: [columnNo++], data: 'interest_category', orderable: false,
                render: value => categoryList.filter(it => value?.split(',').includes(`${it.id}`)).map(it => it.name).join(', ') || '-'
              }, {
                targets: [columnNo++], data: 'level', orderable: true,
                render: value => levelList.find(it => it.id == value)?.name || '-'
              }, {
                targets: [columnNo++], data: 'company_name', orderable: false,
                render: value => (value || '') == '' ? '일반회원' : '기업회원'
              }, {
                targets: [columnNo++], data: 'left_ticket', orderable: false,
                render: value => value == null ? 0 : value
              }, {
                targets: [columnNo++], data: 'status', orderable: false,
                render: value => value == -1 ? '탈퇴' : '정상'
              }, {
                targets: [columnNo++], data: 'signup_date', orderable: false,
                render: value => value
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<button type='button' class='btn btn-outline-success btn-sm m-2'>${strings.modal.confirm}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onDetail(item.id))
                    .end()
                    .append(`<button type='button' class='btn btn-outline-warning btn-sm m-2'>티켓추가</button>`)
                    .find('button:eq(1)')
                    .on('click', () => onAddTicket(item.id))
                    .end()
                    .append(`<button type='button' class='btn btn-outline-danger btn-sm m-2'>${strings.common.delete}</button>`)
                    .find('button:eq(2)')
                    .on('click', () => onDelete(item.id));
                }
              }],
              fnCreatedRow: function (row, item, index) {
                  $('td:eq(0)', row).html((index + 1));
              },
              order: [[0, 'DESC']],
              ajax: {
                url: 'member/list',
                data: (req) => {
                  req.gender = genderRef.current;
                  req.age = ageRef.current;
                  req.level = ratingRef.current;
                  req.category = categoryRef.current;
                  req.from = fromRef.current;
                  req.to = toRef.current;
                  req.type = $('#searchType').val();
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.facility.id}</th>
              <th>{strings.facility.member_name}</th>
              <th>{strings.facility.contact}</th>
              <th>{strings.common.gender}</th>
              <th>{strings.common.age}</th>
              <th>{strings.member.interest_category}</th>
              <th>{strings.facility.member_level}</th>
              <th>{'회원유형'}</th>
              <th>{'남은 티켓수'}</th>
              <th>{'상태'}</th>
              <th>{'가입일'}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
      <AddTicketPopup
        {...popup}
        onClose={refresh => {
          hidePopup();
          if (refresh === true) {
            context.addNotification(strings.modal.alert, '티켓이 추가되었습니다.');
            refreshTable(false);
          }
        }} />
    </Container>
  );

}

export default withRouter(Member);
