import React, { useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import ReactQuill from "react-quill";

const FaqPopup = ({ isOpen, id, onClose }) => {

  const context = useContext(PageSettings);

  const [info, setInfo] = useState(null);

  const getInfo = () => {
    if (id < 1) {
      setInfo(null);
      return;
    }

    context.get(
      `support/faq/${id}`,
      {},
      res => {
        setInfo(res);
      }
    );
  }

  const onSave = e => {
    e.preventDefault();

    context.showConfirm(strings.modal.save_confirm, async res => {
      if (res !== true) {
        return;
      }

      context.post(
        `support/faq/${id}`,
        {
          type: info?.type || Object.keys(strings.post.faq_type)[0],
          title: info?.title || '',
          content: info?.content || '',
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          onClose(true);
        }
      );
    });
  }

  return (
    <Modal size={'lg'} isOpen={isOpen} toggle={onClose} onOpened={getInfo}>
      <form onSubmit={onSave}>
        <ModalHeader className="popup-nickname" toggle={onClose}>
          {strings.post.faq_save}
        </ModalHeader>
        <ModalBody>
          <div className='row'>
            <label className='col-xs-2 col-form-label'>{strings.common.type}</label>
            <div className="col-xs-9">
              <select
                required
                className='form-control'
                value={info?.type || Object.keys(strings.post.faq_type)[0]}
                onChange={e => setInfo({ ...info, type: e.target.value })}>
                {
                  Object.keys(strings.post.faq_type).map(value => (
                    <option key={value} value={value}>{strings.post.faq_type[value]}</option>
                  ))
                }
              </select>
            </div>
          </div>
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{strings.common.title}</label>
            <div className="col-xs-9">
              <input
                required
                type='text'
                className='form-control'
                autoComplete='off'
                value={info?.title || ''}
                onChange={e => setInfo({ ...info, title: e.target.value })} />
            </div>
          </div>
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{strings.common.content}</label>
            <div className='col-xs-9'>
              <ReactQuill
                theme='snow'
                className=''
                value={info?.content || ''}
                onChange={content => {
                  setInfo(prev => {
                    return {
                      ...prev,
                      content: content
                    }
                  });
                }} />
              {/*<textarea
                required
                className='form-control'
                rows={20}
                value={info?.content || ''}
                onChange={e => setInfo({ ...info, content: e.target.value })} />*/}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-default" type='button' onClick={onClose}>{strings.modal.close}</button>
          <button className="btn btn-success" type='submit'>{strings.common.save}</button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default FaqPopup;
