export default {
  admin: 'tshare Admin',
  menu: {
    dashboard: 'Dashboard',
    member: 'Member Management',
    facility: 'Facility Management',
    affiliate: 'Affiliate Request',
    payment: 'Payment Management',
    category: 'Category Management',
    push: 'Push Management',
    post: 'Post Management',
    reservation: 'Reservation Management',
    change_password: 'Password',
    log_out: 'Logout',
  },
  modal: {
    alert: 'Alert',
    confirm: 'Confirm',
    ok: 'Ok',
    no: 'No',
    cancel: 'Cancel',
    close: 'Close',
    apply: 'Apply',
    save_confirm: 'Are you sure to save?',
    delete_confirm: 'Are you sure to delete?',
  },
  msg: {
    applied: 'applied',
    saved: 'saved',
    deleted: 'deleted'
  },
  common: {
    all: 'All',
    save: 'Save',
    delete: 'Delete',
    edit: 'Edit',
    add: 'Add',
    search: 'Search',
    name: 'Name',
    nickname: 'Nickname',
    manage: 'Manage',
    title: 'Title',
    content: 'Content',
    image: 'Image',
    status: 'Status',
    create_date: 'Create Date',
    update_date: 'Update Date',
    download: 'Download Excel',
    write: 'Write',
    view: 'View',
    show: 'Show',
    hide: 'Hide',
    type: 'Type',
    total: 'Total',
    year: 'Year',
    month: 'Month',
    date: 'Date',
    unknown: 'Unknown',
    normal: 'Normal',
    gender: 'Gender',
    age: 'Age',
    area: 'Area',
    back: 'Back',
    no_data: 'No Data Available',
    select_all: 'Select All',
    phone: 'Phone',
    keyword: 'Keyword'
  },
  login: {
    id: 'Please enter your ID',
    password: 'Please enter a password',
    login: 'Login',
  },
  change_password: {
    current_password: 'Current password',
    new_password: 'New password',
    confirm_password: 'Confirm password',
    change: 'change',
    password_not_correct: 'password_not_correct',
  },
  dashboard: {
    new_members: 'New Members',
    see_more: 'See More',
    facilities: 'Facilities: Total {0} cases',
    push_history: 'Push History',
    affiliate_request: 'Affiliate Requests({0})'
  },
  facility: {
    detail: 'Facility Detail',
    name: 'Facility Name',
    category: 'Facility Category',
    rating: 'Facility Rating',
    reg_date: 'Reg Date',
    address: 'Address',
    contact_info: 'Contact Info',
    visitor_number: 'Number of visitors',
    add: 'Add Facility',
    visit_date: 'Visit Date',
    main_info: 'Facility Info',
    visitor: 'Facility Visitors',
    member_name: 'Member Name',
    member_level: 'Member Level',
    contact: 'Contact',
    id: 'ID',
    date_list: [
      'Today',
      'Yesterday',
      'This month',
      'This week',
      'Last 30 days',
      'Last 7 days',
      'Last month',
      'Last week',
      'Last year',
    ],
    main_image: 'Main Image',
    work_hour_holiday: 'Working hours and holidays',
    work_hour: 'Working hours',
    weekend_work_hour: 'Weekend Working hours',
    holidays: {
      W: 'Weekend',
      H: 'Holiday',
      A: 'Alternative Holiday',
      S: 'Select a holiday'
    },
    additional: 'Additional',
    map_api: 'Map API',
    client_id: 'Client ID',
    client_secret: 'Client Secret',
    google_map_api: 'Google Map API',
    api_key: 'API Key',
    map_type: 'Map Type',
    map: 'Map',
    google_map: 'Google Map',
    select_category: 'Select category',
    select_area: 'Select area',
    select_image: 'Select main image',
    input_service: 'Please add service',
    recommend: 'Recommend',
    admin: 'Admin Id',
    input_password: 'Please input password',
    confirm_password: 'Password does not match',
  },
  member: {
    detail: 'Member Detail',
    interest_category: 'Interest Category',
    interest_area: 'Interest Area',
    main_info: 'Member Main Info',
    visit_log: 'Visited Facility Info',
    email: 'Email',
    reset_pwd: 'Reset Pwd',
    sign_out: 'Sign Out',
    this_month_remain: 'This month Remaining',
    remain_gift: 'Remaining Gift',
    last_month_remain: 'Last month Remaining',
    avg_remain: 'Average Remaining',
    join_date: 'Joined Date',
    membership: 'Membership',
    membership_date: 'Membership Date',
    sign_out_confirm: 'Are you sure to sign out?',
    reset_pwd_confirm: 'Are you sure to reset pwd?'
  },
  category: {
    category: 'Interest Category',
    category_save: 'Interest Category Save/Edit',
    rating: 'Member/Facility Rating',
    rating_save: 'Member/Facility Rating Save/Edit',
    price: 'Price',
    gift: 'Gift Use'
  },
  push_history: {
    type: 'Push Type',
    summary: 'Content Summary',
    receive_group: 'Receiving group',
    issue_date: 'Issue Date',
    date_from: 'From',
    date_to: 'To',
    remain_ticket: 'Remaining Ticket',
    content: 'Delivery Contents',
    attachment: 'Attachment',
    send_push: 'Send Push ({0})',
    select_type: 'Please select type.',
    send_no_member: 'No member will receive push.',
    push_type: {
      'E': 'Email',
      'P': 'Push Alarm'
    },
    age: '{0}s',
    gender: {
      'M': 'Male',
      'F': 'Female'
    }
  },
  payment: {
    detail: 'Payment Detail',
    level_name: 'Level name',
    start_date: 'Start Date',
    code: 'Coupon Code',
    price: 'Price',
    discount: 'Discount',
  },
  affiliate_request: {
    contents: 'Contents',
    detail: 'Affiliate Detail',
    received_date: 'Received Date',
    read: 'Read',
    unread: 'Unread',
    memo: 'Memo'
  },
  post: {
    faq: 'Faq',
    faq_save: 'Faq Save/Edit',
    faq_type: {
      'S': 'Signup',
      'P': 'Partnership',
      'M': 'Membership',
    },
    notice: 'Notice',
    notice_save: 'Notice Save/Edit',
    notice_type: {
      'N': 'Notice',
      'E': 'Event'
    },
    file: 'File',
    event: 'Event'
  },
  reservation: {
    detail: 'Reservation Detail',
    day_list: ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'],
    not_select_prev_date: 'Select after today',
    date: 'Reservation date',
    time: 'Reservation time',
    visit_count: 'Visit count',
    pending: 'Pending',
    confirm: 'Confirm',
    confirm_msg: 'Are you sure?',
    deny: 'Deny',
    no_content: 'No content'
  }
}
