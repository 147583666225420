import SearchSelect from "../../../components/control/search-select";
import strings from "../../../lang/strings";
import { useContext, useRef, useState } from "react";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import { PageSettings } from "../../../config/page-settings";
import $ from 'jquery';
import FaqPopup from "../popup/faq-popup";

const FaqFragment = () => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => tableRef.current?.draw(refresh);

  const searchOptions = [
    { value: 'title', name: strings.common.title },
    { value: 'content', name: strings.common.content },
  ];
  const faqType = [
    { value: '', name: strings.common.all }
  ];
  for (let value in strings.post.faq_type) {
    faqType.push({ value, name: strings.post.faq_type[value] });
  }


  const [popup, setPopup] = useState({
    isOpen: false,
    id: 0
  });
  const showPopup = id => setPopup({
    isOpen: true,
    id
  });
  const hidePopup = () => setPopup({
    isOpen: false,
    info: 0
  });

  const onEdit = id => showPopup(id);

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        `support/faq/${id}`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-horizontal form-inline'>
          <SearchSelect
            id='searchType'
            className='mr-2'
            value={faqType[0].value}
            data={faqType}
            onChange={() => refreshTable(true)} />
          <SearchSelect
            id='searchColumn'
            value={searchOptions[0].value}
            data={searchOptions}
            onChange={() => refreshTable(true)} />
          <SearchInput
            id='searchInput'
            action={() => refreshTable(true)} />
          <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => refreshTable(true)}>
            {strings.common.search}
          </button>
          <button type='button' className='btn btn-primary btn-sm ml-md-2' onClick={() => onEdit(0)}>
            {strings.common.add}
          </button>
        </div>
      </div>
      <div className='col-md-12 mt-3'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'type', orderable: true,
              render: value => strings.post.faq_type[value] || ''
            }, {
              targets: [columnNo++], data: 'title', orderable: true,
            }, {
              targets: [columnNo++], data: 'create_date', orderable: true,
            }, {
              targets: [columnNo++], data: 'id', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html(`<button type='button' class='btn btn-outline-success btn-sm m-2'>${strings.common.edit}</button>`)
                  .find('button:eq(0)')
                  .on('click', () => onEdit(item.id))
                  .end()
                  .append(`<button type='button' class='btn btn-outline-danger btn-sm m-2'>${strings.common.delete}</button>`)
                  .find('button:eq(1)')
                  .on('click', () => onDelete(item.id));
              }
            }],
            fnCreatedRow: function (row, item, index) {
                $('td:eq(0)', row).html((index + 1));
            },
            order: [[0, 'DESC']],
            ajax: {
              url: 'support/faq',
              data: (req) => {
                req.type = $('#searchType').val();
                req.column = $('#searchColumn').val();
                req.keyword = $('#searchInput').val();
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.common.type}</th>
            <th style={{ width: '50%' }}>{strings.common.title}</th>
            <th>{strings.push_history.issue_date}</th>
            <th>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody />
        </DataTable>
      </div>

      <FaqPopup
        {...popup}
        onClose={refresh => {
          hidePopup();
          if (refresh === true) {
            refreshTable(false);
          }
        }} />
    </div>
  );

}

export default FaqFragment;
