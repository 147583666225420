import strings from "../../lang/strings";
import { useEffect, useState } from "react";
import querystring from "query-string";
import { Panel, PanelBody } from "../../components/panel/panel";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import MemberInfoFragment from "./fragment/info-fragment";
import MemberVisitFragment from "./fragment/visit-log-fragment";

const MemberDetail = ({ id, history, location }) => {

  const tabs = [
    { title: strings.member.main_info, component: (id) => <MemberInfoFragment id={id} /> },
    { title: strings.member.visit_log, component: (id) => <MemberVisitFragment id={id} /> },
  ];
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const searchQS = querystring.parse(location.search);
    setActiveTab(searchQS.tab || 0);
  }, [location.search]);

  const toggleTab = tab => {
    const searchQS = {};
    searchQS.tab = tab;
    history.push('?' + querystring.stringify(searchQS));
  }

  return (
    <Panel>
      <Nav tabs className="nav-tabs-inverse">
        {tabs.map((menu, idx) => (
          <NavItem key={menu.title}>
            <NavLink className={classnames({ active: activeTab == idx })} onClick={() => toggleTab(idx)}>
              <span className="d-none d-xs-inline">{menu.title}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <PanelBody>
        {tabs[activeTab].component(id)}
      </PanelBody>
    </Panel>
  );

}

export default withRouter(MemberDetail);
