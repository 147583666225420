import strings from "../../lang/strings";
import FaqFragment from "./fragment/faq";
import NoticeFragment from "./fragment/notice";
import { Panel, PanelBody } from "../../components/panel/panel";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import querystring from "query-string";

const Post = ({ location, history }) => {

  const tabs = [
    { title: strings.post.faq, component: () => <FaqFragment /> },
    { title: strings.post.notice, component: () => <NoticeFragment /> },
  ];
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const searchQS = querystring.parse(location.search);
    setActiveTab(searchQS.tab || 0);
  }, [location.search]);

  const toggleTab = tab => {
    const searchQS = {};
    searchQS.tab = tab;
    history.push('?' + querystring.stringify(searchQS));
  }

  return (
    <Panel>
      <Nav tabs className="nav-tabs-inverse">
        {tabs.map((menu, idx) => (
          <NavItem key={menu.title}>
            <NavLink className={classnames({ active: activeTab == idx })} onClick={() => toggleTab(idx)}>
              <span className="d-none d-xs-inline">{menu.title}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <PanelBody>
        {tabs[activeTab].component()}
      </PanelBody>
    </Panel>
  );

}

export default withRouter(Post);
