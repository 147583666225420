import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import strings from "../../../lang/strings";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";

const ReviewFragment = () => {

  const context = useContext(PageSettings);

  const [totalCount, setTotalCount] = useState(0);
  const [list, setList] = useState([]);

  useEffect(() => {
    context.get(
      'dashboard/review',
      {},
      response => {
        setTotalCount(response.totalCount);
        setList(response.list);
      }
    );
  }, []);

  return (
    <Container
      title={strings.formatString('리뷰 관리', totalCount.currencyFormat())}
      rightButton={(
        <Link to="/review" style={{ color: '#fff' }}>
          {strings.dashboard.see_more} <i className="fa fa-arrow-alt-circle-right" />
        </Link>
      )}>
      <Table>
        <thead>
        <tr>
          <th>{'ID'}</th>
          <th>{'별점'}</th>
          <th>{'내용'}</th>
          <th>{'등록일자'}</th>
        </tr>
        </thead>
        <tbody>
        {
          list.length < 1 ?
            <tr>
              <td colSpan={5}>{strings.common.no_data}</td>
            </tr>
            :
            list.map((tr, idx) => (
              <tr key={idx}>
                <td>{tr.email}</td>
                <td>{tr.star}</td>
                <td>{tr.content}</td>
                <td>{tr.create_date}</td>
              </tr>
            ))
        }
        </tbody>
      </Table>
    </Container>
  );
}

export default ReviewFragment;
