import strings from "../../../lang/strings";
import { PageSettings } from "../../../config/page-settings";
import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import $ from "jquery";
import { useContext, useEffect, useRef, useState } from "react";
import DateTime from "react-datetime";
import { FORMAT } from "../../../config/const";
import dayjs from "dayjs";

const MemberVisitFragment = ({ id }) => {

  const context = useContext(PageSettings);

  const categoryList = context.getSettingList('category');
  const levelList = context.getSettingList('level');

  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const fromRef = useRef('');
  const toRef = useRef('');

  useEffect(() => {
    fromRef.current = from;
  }, [from]);
  useEffect(() => {
    toRef.current = to;
  }, [to]);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => tableRef.current?.draw(refresh);

  const searchOptions = [
    { value: 'name', name: strings.facility.name },
    { value: 'address', name: strings.facility.address },
    { value: 'phone', name: strings.common.phone },
  ];

  const getFilterOption = () => {
    return {
      id: id,
      from: fromRef.current,
      to: toRef.current,
      column: $('#searchColumn').val(),
      keyword: $('#searchInput').val(),
    }
  }

  const onDownload = () => {
    context.get(
      'member/visit_log',
      {
        start: 0,
        length: 0,
        ...getFilterOption(),
        download: true
      },
      response => {
        const excelData = [];
        response.forEach(it => {
          excelData.push([
            it.id,
            it.name,
            categoryList.filter(c => it.category.split(',').includes(`${c.id}`)).map(it => it.name).join(', ') || '-',
            it.address,
            it.phone,
            levelList.find(l => l.id == it.level)?.name || '-',
            it.visit_date,
          ]);
        });

        context.downloadExcel(`member-visit-${dayjs().format(FORMAT.DATE)}.csv`, [
          [
            '#',
            strings.facility.name,
            strings.facility.category,
            strings.facility.address,
            strings.facility.contact_info,
            strings.facility.rating,
            strings.facility.visit_date,
          ],
          ...excelData
        ]);
      }
    );
  };

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-group row form-inline'>
          <label className='col-md-1 col-form-label'>{strings.facility.visit_date}</label>
          <div className='col-md-11'>
            <div className='form-inline'>
              <DateTime
                dateFormat={FORMAT.DATE}
                timeFormat={false}
                inputProps={{ placeholder: strings.push_history.date_from }}
                value={from}
                closeOnSelect={true}
                className="ml-md-2"
                onChange={(e) => {
                  if (typeof e === 'string') {
                    setFrom(e);
                  } else {
                    setFrom(e.format(FORMAT.DATE));
                  }
                }} />
              <label className='control-label'>&nbsp;~</label>
              <DateTime
                dateFormat={FORMAT.DATE}
                timeFormat={false}
                inputProps={{ placeholder: strings.push_history.date_to }}
                value={to}
                closeOnSelect={true}
                className="ml-md-1 mr-4"
                onChange={(e) => {
                  if (typeof e === 'string') {
                    setTo(e);
                  } else {
                    setTo(e.format(FORMAT.DATE));
                  }
                }} />
            </div>
          </div>
        </div>
        <div className='form-horizontal row form-inline'>
          <label className='col-md-1 col-form-label'>{strings.common.keyword}</label>
          <div className='col-md-10'>
            <div className='form-inline'>
              <SearchSelect
                id='searchColumn'
                value={searchOptions[0].value}
                data={searchOptions}
                onChange={() => refreshTable(true)} />
              <SearchInput
                id='searchInput'
                action={() => refreshTable(true)} />
              <button type='button' className='btn btn-success btn-sm ml-md-5' onClick={() => refreshTable(true)}>
                {strings.common.search}
              </button>
              <div className='ml-md-1'>
                <button type='button' className='btn btn-info btn-sm' onClick={onDownload}>
                  {strings.common.download}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className='mt-3' />
      <div className='col-md-12'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'name', orderable: false,
            }, {
              targets: [columnNo++], data: 'category', orderable: false,
              render: value => categoryList.filter(c => value.split(',').includes(`${c.id}`)).map(it => it.name).join(', ') || '-'
            }, {
              targets: [columnNo++], data: 'address', orderable: false,
            }, {
              targets: [columnNo++], data: 'phone', orderable: false,
            }, {
              targets: [columnNo++], data: 'level', orderable: false,
              render: value => levelList.find(it => it.id == value)?.name || '-'
            }, {
              targets: [columnNo++], data: 'visit_date', orderable: false,
            }],
            order: [[0, 'DESC']],
            ajax: {
              url: 'member/visit_log',
              data: (req) => {
                Object.assign(req, getFilterOption());
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.facility.name}</th>
            <th>{strings.facility.category}</th>
            <th>{strings.facility.address}</th>
            <th>{strings.facility.contact_info}</th>
            <th>{strings.facility.rating}</th>
            <th>{strings.facility.visit_date}</th>
          </tr>
          </thead>
          <tbody />
        </DataTable>
      </div>
    </div>
  );

}

export default MemberVisitFragment;
