import Container from "../../components/control/container";
import strings from "../../lang/strings";
import { PageSettings } from "../../config/page-settings";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { useContext, useRef, useState } from "react";
import dayjs from "dayjs";
import RequestDetailPopup from "./popup/request-detail-popup";

const ChangeDevice = ({ history }) => {

  const context = useContext(PageSettings);

  const [selectedInfo, setSelectedInfo] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => tableRef.current?.draw(refresh);

  const onDetail = item => {
    setSelectedInfo(item);
    setShowPopup(true);
  };

  const onChangeStatus = (id, status) => {
    context.showConfirm(status == 1 ? '승인하시겠습니까?' : '거부하시겠습니까?', res => {
      if (res !== true) {
        return;
      }

      context.post(
        `member/change_device_status`,
        {
          id,
          status
        },
        () => {
          context.addNotification(strings.modal.alert, status == 1 ? '승인되었습니다.' : '거부되었습니다.');
          refreshTable(false);
        }
      );
    });
  };

  const getFilterOption = () => {
    return {
      // column: $('#searchColumn').val(),
      keyword: $('#searchInput').val(),
    }
  }

  return (
    <Container title={strings.menu.change_device}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchInput
              id='searchInput'
              placeholder='전화번호'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => refreshTable(true)}>
              {strings.common.search}
            </button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'phone', orderable: true,
              }, {
                targets: [columnNo++], data: 'device_id', orderable: true,
              }, {
                targets: [columnNo++], data: 'reason', orderable: false,
              }, {
                targets: [columnNo++], data: 'status', orderable: true,
                render: value => value == 0 ? '요청' : value == 1 ? '승인' : '거부'
              }, {
                targets: [columnNo++], data: 'create_date', orderable: true,
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  if (item.status == 0) {
                    $(td).html(`<button type='button' class='btn btn-outline-success btn-sm m-2'>${'승인'}</button>`)
                      .find('button:eq(0)')
                      .on('click', () => onChangeStatus(item.id, 1))
                      .end()
                      .append(`<button type='button' class='btn btn-outline-danger btn-sm m-2'>${'거부'}</button>`)
                      .find('button:eq(1)')
                      .on('click', () => onChangeStatus(item.id, -1));
                  } else {
                    $(td).html(`<button type='button' class='btn btn-outline-success btn-sm m-2'>확인</button>`)
                      .find('button:eq(0)')
                      .on('click', () => onDetail(item));
                  }
                }
              }],
              order: [[0, 'DESC']],
              ajax: {
                url: 'member/change_device_list',
                data: (req) => {
                  Object.assign(req, getFilterOption());
                }
              },
            }}>
            <thead>
              <tr>
                <th>#</th>
                <th>{'전화번호'}</th>
                <th>{'디바이스 아이디'}</th>
                <th>{'변경 사유'}</th>
                <th>{strings.common.status}</th>
                <th>{'요청일'}</th>
                <th>{strings.common.manage}</th>
              </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
      <RequestDetailPopup 
        isOpen={showPopup}
        info={selectedInfo}
        onClose={() => setShowPopup(false)}
      />
    </Container>
  );

}

export default withRouter(ChangeDevice);
