import Container from "../../components/control/container";
import strings from "../../lang/strings";
import { PageSettings } from "../../config/page-settings";
import DateTime from "react-datetime";
import { FORMAT } from "../../config/const";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

const Facility = ({ history }) => {

  const context = useContext(PageSettings);

  const [rating, setRating] = useState(context.getSettingList('level').map(it => it.id));
  const [category, setCategory] = useState(context.getSettingList('category').map(it => it.id));
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const ratingRef = useRef('');
  const categoryRef = useRef('');
  const fromRef = useRef('');
  const toRef = useRef('');

  useEffect(() => {
    ratingRef.current = rating.join(',');
  }, [rating]);
  useEffect(() => {
    categoryRef.current = category.join(',');
  }, [category]);
  useEffect(() => {
    fromRef.current = from;
  }, [from]);
  useEffect(() => {
    toRef.current = to;
  }, [to]);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => tableRef.current?.draw(refresh);

  const searchOptions = [
    { value: 'name', name: strings.facility.name },
    { value: 'phone', name: strings.common.phone },
  ];

  const onDetail = id => history.push(`/facility/${id}`);

  const onRecommend = id => {
    context.post(
      `facility/recommend/${id}`,
      {},
      () => refreshTable(false)
    );
  }

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        `facility/${id}`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  const onDownload = () => {
    context.get(
      'facility/list',
      {
        start: 0,
        length: 0,
        ...getFilterOption(),
        download: true
      },
      response => {
        const categoryList = context.getSettingList('category');
        const levelList = context.getSettingList('level');

        const excelData = [];
        response.forEach(it => {
          excelData.push([
            it.id,
            it.name,
            categoryList.filter(c => it.category.split(',').includes(`${c.id}`)).map(it => it.name).join(', ') || '-',
            it.address,
            it.phone,
            levelList.find(l => l.id == it.level)?.name || '-',
            it.visitor.currencyFormat(),
            it.recommend,
            it.create_date
          ]);
        });

        context.downloadExcel(`facility-${dayjs().format(FORMAT.DATE)}.csv`, [
          [
            '#',
            strings.facility.name,
            strings.facility.category,
            strings.facility.address,
            strings.facility.contact_info,
            strings.facility.rating,
            strings.facility.visitor_number,
            strings.facility.recommend,
            strings.common.create_date
          ],
          ...excelData
        ]);
      }
    );
  }

  const getFilterOption = () => {
    return {
      level: ratingRef.current,
      category: categoryRef.current,
      from: fromRef.current,
      to: toRef.current,
      column: $('#searchColumn').val(),
      keyword: $('#searchInput').val(),
    };
  }

  return (
    <Container title={strings.menu.facility}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-group row form-inline'>
            <label className='col-md-1 col-form-label'>{strings.facility.rating}</label>
            <div className="col-md-10">
              <div className='form-inline'>
                {
                  context.getSettingList('level').map((level, idx) => (
                    <label key={idx} className='control-label m-r-20'>
                      <input
                        type='checkbox' name='level' checked={rating.includes(level.id)} value={level.id}
                        onChange={() => {
                          setRating(prev => prev.includes(level.id) ? prev.filter(it => it != level.id) : [...prev, level.id]);
                        }} />&nbsp;{level.name}
                    </label>
                  ))
                }
              </div>
            </div>
          </div>
          <div className='form-group row form-inline'>
            <label className='col-md-1 col-form-label'>{strings.member.interest_category}</label>
            <div className="col-md-10">
              <div className='form-inline'>
                {
                  context.getSettingList('category').map((cate, idx) => (
                    <label key={idx} className='control-label m-r-20'>
                      <input
                        type='checkbox' name='category' checked={category.includes(cate.id)} value={cate.id}
                        onChange={() => {
                          setCategory(prev => prev.includes(cate.id) ? prev.filter(it => it != cate.id) : [...prev, cate.id]);
                        }} />&nbsp;{cate.name}
                    </label>
                  ))
                }
              </div>
            </div>
          </div>
          <div className='form-group row form-inline'>
            <label className='col-md-1 col-form-label'>{strings.facility.visit_date}</label>
            <div className='col-md-10'>
              <div className='form-inline'>
                <DateTime
                  dateFormat={FORMAT.DATE}
                  timeFormat={false}
                  inputProps={{ placeholder: strings.push_history.date_from }}
                  defaultValue={from}
                  closeOnSelect={true}
                  className="ml-md-2"
                  onChange={(e) => {
                    if (typeof e === 'string') {
                      setFrom(e);
                    } else {
                      setFrom(e.format(FORMAT.DATE));
                    }
                  }} />
                <label className='control-label'>&nbsp;~</label>
                <DateTime
                  dateFormat={FORMAT.DATE}
                  timeFormat={false}
                  inputProps={{ placeholder: strings.push_history.date_to }}
                  defaultValue={to}
                  closeOnSelect={true}
                  className="ml-md-1 mr-4"
                  onChange={(e) => {
                    if (typeof e === 'string') {
                      setTo(e);
                    } else {
                      setTo(e.format(FORMAT.DATE));
                    }
                  }} />
              </div>
            </div>
          </div>
          <div className='form-horizontal row form-inline'>
            <label className='col-md-1 col-form-label'>{strings.common.keyword}</label>
            <div className='col-md-10'>
              <div className='form-inline'>
                <SearchSelect
                  id='searchColumn'
                  value={searchOptions[0].value}
                  data={searchOptions}
                  onChange={() => refreshTable(true)} />
                <SearchInput
                  id='searchInput'
                  action={() => refreshTable(true)} />
                <button type='button' className='btn btn-success btn-sm ml-md-5' onClick={() => refreshTable(true)}>
                  {strings.common.search}
                </button>
                <div className='ml-md-1'>
                  <button type='button' className='btn btn-info btn-sm' onClick={onDownload}>
                    {strings.common.download}
                  </button>
                  <button type='button' className='btn btn-primary btn-sm ml-md-1' onClick={() => onDetail(0)}>
                    {strings.facility.add}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className='mt-3' />
        <div className='col-md-12'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'name', orderable: true,
              }, {
                targets: [columnNo++], data: 'category', orderable: true,
                render: value => context.getSettingList('category').filter(it => value.split(',').includes(`${it.id}`)).map(it => it.name).join(', ') || '-'
              }, {
                targets: [columnNo++], data: 'address', orderable: true,
              }, {
                targets: [columnNo++], data: 'phone', orderable: true,
              }, {
                targets: [columnNo++], data: 'level', orderable: true,
                render: value => context.getSettingList('level').find(it => it.id == value)?.name || '-'
              }, {
                targets: [columnNo++], data: 'visitor', orderable: true,
                render: value => value.currencyFormat()
              }, {
                targets: [columnNo++], data: 'recommend', orderable: true,
                createdCell: (td, value, item) => {
                  $(td).html(`<input type='checkbox' ${value == 1 ? 'checked' : ''} />`)
                    .find('input')
                    .on('change', () => onRecommend(item.id));
                }
              }, {
                targets: [columnNo++], data: 'create_date', orderable: true,
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<button type='button' class='btn btn-outline-success btn-sm m-2'>${strings.common.edit}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onDetail(item.id))
                    .end()
                    .append(`<button type='button' class='btn btn-outline-danger btn-sm m-2'>${strings.common.delete}</button>`)
                    .find('button:eq(1)')
                    .on('click', () => onDelete(item.id));
                }
              }],
              fnCreatedRow: function (row, item, index) {
                  $('td:eq(0)', row).html((index + 1));
              },
              order: [[0, 'DESC']],
              ajax: {
                url: 'facility/list',
                data: (req) => {
                  Object.assign(req, getFilterOption());
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.facility.name}</th>
              <th>{strings.facility.category}</th>
              <th>{strings.facility.address}</th>
              <th>{strings.facility.contact_info}</th>
              <th>{strings.facility.rating}</th>
              <th>{strings.facility.visitor_number}</th>
              <th>{strings.facility.recommend}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
    </Container>
  );

}

export default withRouter(Facility);
