import Container from "../../components/control/container";
import strings from "../../lang/strings";
import { PageSettings } from "../../config/page-settings";
import { FORMAT, STATUS } from "../../config/const";
import DataTable from "../../components/control/data-table";
import { useContext, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { inject } from "mobx-react";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import $ from "jquery";
import ReservationPopup from "./popup/reservation-popup";

const Reservation = ({ rootStore }) => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => tableRef.current?.draw(refresh);

  const searchOptions = [
    { value: 'email', name: strings.facility.id },
    { value: 'name', name: strings.common.name },
    { value: 'phone', name: strings.common.phone },
  ];

  const [month, setMonth] = useState(dayjs().format(FORMAT.MONTH));
  const [dateList, setDateList] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const selectedDateRef = useRef(dayjs().format(FORMAT.DATE));

  const [showPopup, togglePopup] = useState(false);
  const [id, setId] = useState(0);
  const showDetail = id => {
    if (dayjs(selectedDateRef.current).isBefore(dayjs().startOf('date'))) {
      context.showAlert('지난 예약은 상태변경 불가능합니다.');
      return;
    }
    setId(id);
    togglePopup(true);
  }

  useEffect(() => {
    getDateList();
  }, [month]);

  useEffect(() => {
    selectedDateRef.current = selectedDate;
    refreshTable(true);
  }, [selectedDate]);

  const getDateList = () => {
    let firstDate = dayjs(dayjs(month).startOf('month')).startOf('week');
    const endDate = dayjs(dayjs(month).endOf('month')).endOf('week');

    let list = [];
    while (firstDate.isSame(endDate) || firstDate.isBefore(endDate)) {
      list.push({
        date: firstDate.format(FORMAT.DATE),
        active: firstDate.format(FORMAT.MONTH) == month
      });
      firstDate = firstDate.add(1, 'day');
    }
    setDateList(list);
  }

  const onChangeMonth = (amount) => {
    setMonth(prev => dayjs(prev).add(amount, 'month').format(FORMAT.MONTH));
  }

  const onSelectDate = date => {
    // if (dayjs(date).isBefore(dayjs().startOf('date'))) {
    //   context.showAlert(strings.reservation.not_select_prev_date);
    //   return;
    // }

    setSelectedDate(date);
  }

  return (
    <Container title={strings.menu.reservation}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='d-flex justify-content-center'>
            <button className='btn btn-icon' onClick={() => onChangeMonth(-1)}>
              <i className='fa fa-chevron-left' />
            </button>
            <h3 className='control-label'>{month}</h3>
            <button className='btn btn-icon' onClick={() => onChangeMonth(1)}>
              <i className='fa fa-chevron-right' />
            </button>
          </div>
          <div className='col-md-6 text-center calendar'>
            <div className='day-list'>
              {strings.reservation.day_list.map((it, idx) => <label key={idx}>{it}</label>)}
            </div>
            <div className='body'>
              {
                dateList.map((it, idx) => (
                  <button
                    key={idx}
                    className={'btn btn-icon ' + (!it.active ? 'inactive' : '') + (it.date == selectedDate ? ' selected' : '')}
                    onClick={() => onSelectDate(it.date)}>
                    {dayjs(it.date).format('D')}
                  </button>
                ))
              }
            </div>
          </div>
        </div>
        <div className='form-group row form-inline m-t-10'>
          <label className='col-md-1 col-form-label'>{strings.common.keyword}</label>
          <div className='col-md-10'>
            <div className='form-inline'>
              <SearchSelect
                id='searchColumn'
                value={searchOptions[0].value}
                data={searchOptions}
                onChange={() => refreshTable(true)} />
              <SearchInput
                id='searchInput'
                action={() => refreshTable(true)} />
              <button type='button' className='btn btn-success btn-sm ml-md-2' onClick={() => setSelectedDate('')}>
                {'전체내역'}
              </button>
            </div>
          </div>
        </div>
        <div className='col-md-12'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'user_email', orderable: true,
              }, {
                targets: [columnNo++], data: 'user_name', orderable: true,
              }, {
                targets: [columnNo++], data: 'date', orderable: false,
              }, {
                targets: [columnNo++], data: null, orderable: false,
                createdCell: (td, value, item) => $(td).html(`${item.start_time} ~ ${item.end_time}`)
              }, {
                targets: [columnNo++], data: 'visit_count', orderable: true,
                render: value => value.currencyFormat()
              }, {
                targets: [columnNo++], data: 'user_gender', orderable: false,
                render: value => strings.push_history.gender[value]
              }, {
                targets: [columnNo++], data: 'user_age', orderable: false,
                render: value => strings.formatString(strings.push_history.age, value)
              }, {
                targets: [columnNo++], data: 'user_interest_category', orderable: false,
                render: value => context.getSettingList('category').filter(it => value?.split(',').includes(`${it.id}`)).map(it => it.name).join(', ') || '-'
              }, {
                targets: [columnNo++], data: 'user_level', orderable: true,
                render: value => context.getSettingList('level').find(it => it.id == value)?.name || '-'
              }, {
                targets: [columnNo++], data: 'status', orderable: true,
                createdCell: (td, value, item) => {
                  switch (value) {
                    case STATUS.PENDING:
                      $(td).html(`<button class="btn btn-outline-info">${strings.reservation.pending}</button>`)
                        .find('button')
                        .bind('click', () => showDetail(item.id));
                      break;
                    case STATUS.NORMAL:
                      $(td).html(`<span class="text-success">${strings.reservation.confirm}</span>`);
                      break;
                    case STATUS.DELETED:
                      $(td).html(`<span class="text-danger">${strings.reservation.deny}</span>`);
                      break;
                    default:
                      $(td).html('');
                  }
                }
              }],
              fnCreatedRow: function (row, item, index) {
                  $('td:eq(0)', row).html((index + 1));
              },
              order: [[0, 'DESC']],
              ajax: {
                url: 'facility/reservation/list',
                data: (req) => {
                  req.facility = rootStore.getMe?.facility;
                  req.date = selectedDateRef.current;
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.facility.id}</th>
              <th>{strings.common.name}</th>
              <th>{strings.reservation.date}</th>
              <th>{strings.reservation.time}</th>
              <th>{strings.reservation.visit_count}</th>
              <th>{strings.common.gender}</th>
              <th>{strings.common.age}</th>
              <th>{strings.member.interest_category}</th>
              <th>{strings.facility.member_level}</th>
              <th>{strings.common.status}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
      <ReservationPopup
        isOpen={showPopup}
        id={id}
        onClose={(refresh = false) => {
          togglePopup(false);
          if (refresh) {
            refreshTable(false);
          }
        }} />
    </Container>
  );

}

export default inject('rootStore')(Reservation);
