import Container from "../../components/control/container";
import strings from "../../lang/strings";
import { useContext, useEffect, useState } from "react";
import { PageSettings } from "../../config/page-settings";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";
import { FORMAT } from "../../config/const";

const PaymentDetail = ({ id, history }) => {

  const context = useContext(PageSettings);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = () => {
    if (id < 1) {
      return;
    }

    context.get(
      `payment/detail/${id}`,
      {},
      response => setInfo(response)
    );
  }

  return (
    <Container title={strings.menu.payment}>
      <div className='form-horizontal form-bordered'>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.facility.member_name}</label>
          <div className='col-xs-6'>
            {info?.user?.name}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.payment.level_name}</label>
          <div className='col-xs-6'>
            {info?.level?.name}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.payment.start_date}</label>
          <div className='col-xs-6'>
            {dayjs(info?.start_date).format(FORMAT.DATE)}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.payment.code}</label>
          <div className='col-xs-6'>
            {info?.code}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.payment.price}</label>
          <div className='col-xs-6'>
            {info?.price?.currencyFormat()}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.payment.discount}</label>
          <div className='col-xs-6'>
            {info?.discount?.currencyFormat()}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-xs-2 col-form-label'>{strings.common.create_date}</label>
          <div className='col-xs-6'>
            {info?.create_date}
          </div>
        </div>
      </div>
      <div className='form-group row m-t-20'>
        <button type='button' className='btn btn-default col-xs-2' onClick={() => history.goBack()}>
          {strings.common.back}
        </button>
      </div>
    </Container>
  );

}

export default withRouter(PaymentDetail);
